import { ReactNode, forwardRef, ElementType } from 'react';
import { cx } from '@/util/cx';
import styles from './Text.module.scss';

type TagName = keyof JSX.IntrinsicElements;

type Props = {
  tag?: TagName;
  align?: 'center' | 'right';
  balance?: boolean;
  children: ReactNode;
  tabIndex?: number;
  breakAll?: boolean;
  theme?: 1 | 2 | 3 | 4 | 5 | 'black' | 'grey' | 'white' | 'error';
  clamp?: 1 | 2 | 3;
  noWrap?: boolean;
  role?: HTMLElement['role'];
};

export const Text = forwardRef<HTMLParagraphElement, Props>(function Text(
  {
    balance,
    children,
    tag = 'p',
    clamp,
    align,
    tabIndex,
    theme,
    breakAll,
    noWrap,
    role,
  }: Props,
  ref
) {
  const Tag = tag as ElementType;

  return (
    <Tag
      ref={ref}
      tabIndex={tabIndex}
      role={role}
      className={cx(
        styles.Text,
        align && styles[`align-${align}`],
        theme && styles[`theme-${theme}`],
        breakAll && styles['break-all'],
        noWrap && styles['no-wrap'],
        clamp && styles[`clamp-${clamp}`],
        balance && styles.balance
      )}
    >
      {children}
    </Tag>
  );
});
