const isMobile =
  typeof navigator === 'object' &&
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

type Config = {
  title: string;
  text: string;
  url: string;
};

const target = '_blank';
const features = 'noopener,noreferrer';

export function facebook({ text, url }: Config) {
  const base = 'https://facebook.com/sharer.php';
  window.open(
    `${base}?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(
      text.replace(/\s+/, ' ')
    )}`,
    target,
    features
  );
}

export function linkedin({ url }: Config) {
  const base = 'https://www.linkedin.com/sharing/share-offsite/';
  window.open(`${base}?url=${encodeURIComponent(url)}`, target, features);
}

export function twitter({ text, url }: Config) {
  const base = 'https://twitter.com/intent/tweet';
  window.open(
    `${base}?text=${encodeURIComponent(
      text.replace(/\s+/, ' ')
    )}&url=${encodeURIComponent(url)}`,
    target,
    features
  );
}

export function whatsapp({ text, url }: Config) {
  const platform = isMobile ? 'api' : 'web';
  const base = `https://${platform}.whatsapp.com/send`;

  window.open(
    `${base}?text=${encodeURIComponent(text.replace(/\s+/, ' ') + ' ' + url)}`,
    target,
    features
  );
}
