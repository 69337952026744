import { Button } from '@/components/Button';
import { Footer } from '@/components/Footer';
import { Flex } from '@/components/Grid';
import { Header } from '@/components/Header';
import { Heading } from '@/components/Heading';
import { Icon } from '@/components/Icon';
import { IconButton, RawIconLink } from '@/components/IconButton';
import { ImageCropper } from '@/components/ImageCropper';
import { Input } from '@/components/Input';
import { LoginCta } from '@/components/LoginCta';
import { Modal, ModalOverlay } from '@/components/Modal';
import { Nav } from '@/components/Nav';
import { Text } from '@/components/Text';
import { Textarea } from '@/components/Textarea';
import { useModal, type ModalProps } from '@/hooks/useModal';
import { useWindowSize } from '@/hooks/useWindowSize';
import { getAccount, logout } from '@/resource/account';
import type { ReportReasonType, SettingsType } from '@/resource/settings';
import { localUrl } from '@/util/env';
import { loadGtag } from '@/util/gtag';
import { queryClient } from '@/util/queryClient';
import * as share from '@/util/share';
import type { SerializableObject } from '@/util/types';
import type { NextComponentType, NextPageContext } from 'next';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import { Nunito_Sans } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { Hydrate, QueryClientProvider } from 'react-query';
import { useBoolean, useCopyToClipboard, useEventListener } from 'usehooks-ts';

import '../styles/global.scss';

const font = Nunito_Sans({ subsets: ['latin'] });

type Props = AppProps<{ settings: SettingsType; noLayout?: boolean }> & {
  Component: NextComponentType<
    NextPageContext,
    SerializableObject,
    SerializableObject
  >;
};

const title = 'NH Helpt';
const description = title;

export default function App({ Component, pageProps }: Props) {
  const modal = useModal();
  const router = useRouter();

  const { settings } = pageProps;

  // Make sure settings are precached.
  queryClient.setQueryData('settings', settings);

  const {
    value: navOpen,
    setFalse: closeNav,
    setTrue: openNav,
  } = useBoolean(false);

  useEffect(() => {
    getAccount().catch(() => logout());
  }, [router.asPath]);

  const bodyInert = modal.isOpen || navOpen;

  useEffect(() => {
    document.documentElement.classList.toggle('no-scroll', bodyInert);
  }, [bodyInert]);

  useEffect(() => {
    router.events.on('routeChangeComplete', closeNav);
    return () => {
      router.events.off('routeChangeComplete', closeNav);
    };
  }, [router, closeNav]);

  // No need for consent because we are using a self
  // hosted version of GA on tikkertje.nhnieuws.nl.
  useEffect(loadGtag, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.settings}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=5"
          />
        </Head>

        <DefaultSeo
          defaultTitle={title}
          titleTemplate={router.asPath === '/' ? title : `%s | ${title}`}
          description={description}
          openGraph={{
            title,
            description,
            images: [
              {
                url: `${localUrl}/images/seo.png`,
                width: 1200,
                height: 630,
              },
            ],
          }}
        />

        <style jsx global>{`
          html {
            --font-family-1: ${font.style.fontFamily};
            --palette-1: ${settings.color_1};
            --palette-2: ${settings.color_2};
            --palette-3: ${settings.color_3};
            --palette-4: ${settings.color_4};
            --palette-5: ${settings.color_5};
            --palette-6: ${settings.color_6};
            --palette-7: ${settings.color_7};
            --palette-8: ${settings.color_8};
          }
        `}</style>

        {pageProps.noLayout ? (
          <Component {...pageProps} />
        ) : (
          <>
            <Header onRequestNav={openNav} inert={bodyInert} />
            <main inert={bodyInert ? '' : null}>
              <Component {...pageProps} />
            </main>
            <Footer
              pages={settings.footer_pages.data || []}
              inert={bodyInert}
            />
            <Nav open={navOpen} onRequestClose={closeNav} />
          </>
        )}

        <ModalFactory settings={settings} />
      </Hydrate>
    </QueryClientProvider>
  );
}

function ModalFactory({ settings }: { settings: SettingsType }) {
  const router = useRouter();
  const modal = useModal();
  const { context, isOpen } = modal;
  const close = useCallback(() => modal.close(), [modal]);

  useEffect(() => {
    router.events.on('routeChangeComplete', close);
    return () => router.events.off('routeChangeComplete', close);
  }, [router, close]);

  return (
    <>
      <ModalOverlay open={isOpen} onRequestClose={close} />

      <Modal
        open={isOpen}
        title={context.type.replace('_', ' ')}
        onRequestClose={close}
        maxWidth={context.maxWidth}
      >
        {context.type === 'login' && <LoginCta title={context.props.title} />}
        {context.type === 'image_cropper' && (
          <ImageCropperModal {...context.props} close={close} isOpen={isOpen} />
        )}
        {context.type === 'share' && (
          <ShareModal {...context.props} close={close} isOpen={isOpen} />
        )}
        {context.type === 'confirm' && (
          <ConfirmModal {...context.props} close={close} isOpen={isOpen} />
        )}
        {context.type === 'mail_preferences' && (
          <MailPreferencesModal
            {...context.props}
            close={close}
            isOpen={isOpen}
          />
        )}
        {context.type === 'report' && (
          <ReportModal
            {...context.props}
            close={close}
            isOpen={isOpen}
            reasons={settings.ad_report_reasons.data}
          />
        )}
        {context.type === 'message' && (
          <MessageModal {...context.props} close={close} isOpen={isOpen} />
        )}
        {context.type === 'image_gallery' && (
          <ImageGalleryModal {...context.props} close={close} isOpen={isOpen} />
        )}
      </Modal>
    </>
  );
}

function MessageModal({
  title,
  description,
  illustration,
  close,
}: ModalProps<'message'>) {
  return (
    <Flex dir="column" gap={3}>
      {illustration && (
        <Flex justify="center">
          <picture>
            <img
              src={`/svg/illustration/nh_${illustration}.svg`}
              width={200}
              alt=""
            />
          </picture>
        </Flex>
      )}

      <Heading align="center" visualLevel={3} balance>
        {title}
      </Heading>

      {typeof description === 'string' ? (
        <Text align="center" balance>
          {description}
        </Text>
      ) : (
        description
      )}

      <Button align="center" onClick={close}>
        Sluiten
      </Button>
    </Flex>
  );
}

function ImageCropperModal({
  title,
  onSave,
  file,
  aspectRatio,
  allowRotation,
  rounded,
}: ModalProps<'image_cropper'>) {
  return (
    <Flex dir="column" gap={3}>
      <Heading align="center" visualLevel={3}>
        {title}
      </Heading>
      <ImageCropper
        key={`${file.name}_${file.size}`}
        original={file}
        aspectRatio={aspectRatio}
        rounded={rounded}
        allowRotation={allowRotation}
        onSave={onSave}
      />
    </Flex>
  );
}

function ConfirmModal({
  title,
  onCancel,
  onConfirm,
  description,
}: ModalProps<'confirm'>) {
  return (
    <Flex dir="column" gap={3} justify="center">
      <Heading balance align="center" visualLevel={3}>
        {title}
      </Heading>

      {typeof description === 'string' ? (
        <Text align="center" balance>
          {description}
        </Text>
      ) : (
        description
      )}

      <Flex justify="space-between">
        <Button theme="secondary" onClick={() => onCancel()}>
          Annuleren
        </Button>
        <Button onClick={() => onConfirm()}>Bevestigen</Button>
      </Flex>
    </Flex>
  );
}

function ImageGalleryModal({
  title,
  images,
  initialIndex,
}: ModalProps<'image_gallery'>) {
  const { width, height } = useWindowSize();
  const imageWidths = images.map((image) => image.width);
  const imageHeights = images.map((image) => image.height);
  const imageWidth = Math.max(...imageWidths) || 16;
  const imageHeight = Math.max(...imageHeights) || 9;
  const maxImageAspectRatio = imageWidth / imageHeight;
  const windowAspectRatio = width / height;

  const max = images.length;
  const [index, setIndex] = useState(initialIndex);

  useEffect(() => setIndex(initialIndex), [initialIndex]);

  const previous = () => setIndex((index) => (index - (1 % max) + max) % max);
  const next = () => setIndex((index) => (index + 1) % max);

  useEventListener('keyup', (e) => {
    if (e.key === 'ArrowLeft') {
      previous();
    } else if (e.key === 'ArrowRight') {
      next();
    }
  });

  return (
    <Flex dir="column" gap={3} justify="center">
      <Heading balance align="center" visualLevel={3}>
        {title}
      </Heading>
      <Flex align="center" gap={{ sm: 1, md: 2 }}>
        <Flex>
          <IconButton
            size={5}
            iconSize={2}
            icon="chevron_left_narrow"
            title="Vorige foto"
            onClick={previous}
          />
        </Flex>
        <Flex grow>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f0f0f0',
              width: '100%',
              backgroundImage: `url(${images[index]?.resize['1200_1200']})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              maxHeight: '40vh',
              aspectRatio:
                maxImageAspectRatio < windowAspectRatio
                  ? windowAspectRatio
                  : maxImageAspectRatio,
            }}
          />
        </Flex>
        <Flex>
          <IconButton
            size={5}
            iconSize={2}
            icon="chevron_right_narrow"
            title="Volgende foto"
            onClick={next}
          />
        </Flex>
      </Flex>
      <Text align="center">
        {index + 1}/{max}
      </Text>
    </Flex>
  );
}

function ShareModal({ close, title, data }: ModalProps<'share'>) {
  const [value, copy] = useCopyToClipboard();

  return (
    <Flex dir="column" gap={3}>
      <Heading align="center" visualLevel={3}>
        {title}
      </Heading>

      <Flex justify="center" gap={{ sm: 1, md: 2 }}>
        <IconButton
          icon="linkedin"
          title="Deel op LinkedIn"
          onClick={() => share.linkedin(data)}
        />
        <IconButton
          icon="whatsapp"
          title="Deel op Whatsapp"
          onClick={() => share.whatsapp(data)}
        />
        <RawIconLink
          icon="mail"
          title="Deel via e-mail"
          href={`mailto:?body=${encodeURIComponent(
            data.url
          )}&subject=${encodeURIComponent(data.title)}`}
        />
        <IconButton
          icon="x"
          title="Deel op X"
          onClick={() => share.twitter(data)}
        />
        <IconButton
          icon="facebook"
          title="Deel op Facebook"
          onClick={() => share.facebook(data)}
        />
      </Flex>

      <Flex dir="column" gap={1}>
        <Input
          label="Of kopieer de link"
          id="share_url"
          type="text"
          readOnly
          key={data.url}
          defaultValue={data.url}
          onFocus={(e) => {
            e.target.select();
            copy(data.url);
          }}
          append={
            value === data.url ? (
              <Icon size={2} theme={2} icon={'check'} />
            ) : undefined
          }
        />
      </Flex>

      <Button theme="outline" align="center" onClick={close}>
        Annuleren
      </Button>
    </Flex>
  );
}

function ReportModal({
  isOpen,
  title,
  onCancel,
  onReport,
  reasons,
}: ModalProps<'report'> & { reasons: ReportReasonType[] }) {
  const [reportReasonId, setReportReasonId] = useState<number | null>(null);
  const [description, setDescription] = useState('');

  // Reset values everytime modal is opened
  useEffect(() => {
    if (isOpen) {
      setReportReasonId(null);
      setDescription('');
    }
  }, [isOpen]);

  return (
    <Flex dir="column" gap={4}>
      <Flex dir="column" gap={2}>
        <Heading align="center">{title}</Heading>
        <p>
          Fijn dat je ons hierop wijst. Kun je aangeven waarom je dit bericht
          niet oké vindt? Kies één van de onderstaande opties. We zullen je
          melding serieus nemen en snel handelen.
        </p>
      </Flex>

      <Flex dir="column" gap={3}>
        <h3>Het bericht bevat:</h3>

        <Flex dir="column" gap={2}>
          {reasons.map(({ id, title }) => (
            <Flex gap={1} align="center" key={id}>
              <input
                id={`report_reason_${id}`}
                name="report_reason"
                type="radio"
                value={id}
                checked={id === reportReasonId}
                onChange={() => setReportReasonId(id)}
              />
              <label htmlFor={`report_reason_${id}`}>{title}</label>
            </Flex>
          ))}
        </Flex>

        <Textarea
          id="report_description"
          value={description}
          placeholder="Als je wilt kun je hier meer informatie toevoegen"
          onChange={(e) => setDescription(e.target.value)}
          maxLength={1024}
        />
      </Flex>

      <Flex justify="space-between">
        <Button theme="secondary" onClick={() => onCancel()}>
          Annuleren
        </Button>
        <Button
          disabled={!reportReasonId}
          onClick={() =>
            reportReasonId != null &&
            onReport({
              reportReasonId,
              description,
            })
          }
        >
          Versturen
        </Button>
      </Flex>
    </Flex>
  );
}

function MailPreferencesModal({
  title,
  onCancel,
  onConfirm,
  currentValue,
  options,
}: ModalProps<'mail_preferences'>) {
  const name = 'mail_frequency';
  const [value, setValue] = useState(currentValue);

  return (
    <Flex gap={3} dir="column">
      <Flex gap={2} dir="column">
        <Heading align="center" visualLevel={3} balance>
          {title}
        </Heading>

        <Flex gap={1} dir="column">
          {options.map((option, i) => (
            <Flex gap={1} align="center" key={option.value}>
              <input
                type="radio"
                name={name}
                id={`${name}_${i}`}
                value={option.value}
                checked={value === option.value}
                onChange={() => setValue(option.value)}
              />
              <label htmlFor={`${name}_${i}`}>{option.label}</label>
            </Flex>
          ))}
        </Flex>
      </Flex>

      <Flex justify="space-between">
        <Button theme="secondary" onClick={() => onCancel()}>
          Annuleren
        </Button>
        <Button onClick={() => onConfirm(value)}>Opslaan</Button>
      </Flex>
    </Flex>
  );
}
