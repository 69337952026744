import { iconSchema } from '@/components/Icon/schema';
import { get, post } from '@/util/api';
import { objectToEntries } from '@/util/objectFromEntries';
import type { CropRect, ImageUploadType } from '@/util/types';
import { uniqueBy } from '@/util/uniqueBy';
import z from 'zod';
import { AccountStore } from './account';
import { authStorage } from './auth';
import { dataArray, dataItem } from './helpers';
import { locationItemSchema } from './location';
import { paginatorSchema } from './paginator';
import { ProfileItemType, profileItemSchema } from './profile';

export const adImageSchema = z.object({
  id: z.number(),
  h: z.number(),
  s: z.number(),
  l: z.number(),
  crop: z.object({
    '16:9': z.object({
      286: z.string(),
      572: z.string(),
      858: z.string(),
      1200: z.string(),
    }),
  }),
  resize: z.object({
    '1200_1200': z.string(),
    '1600_1600': z.string(),
  }),
  width: z.number(),
  height: z.number(),
});

const adActivityItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  phrase: z.string(),
  ad_category_id: z.number(),
});

const adCategoryItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  icon: iconSchema,
  activities: dataArray(adActivityItemSchema).optional().nullable(),
});

const expiresSchema = z.object({
  y: z.number(),
  m: z.number(),
  d: z.number(),
  h: z.number(),
  i: z.number(),
});

export type ExpiresType = z.infer<typeof expiresSchema>;

export const adStatusSchema = z.enum([
  'in_review',
  'update_review',
  'fulfilled',
  'rejected',
  'approved',
]);

export const adItemSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  score: z.number(),
  distance: z.number(),
  expires: expiresSchema.nullable(),
  is_offline: z.boolean(),
  is_public: z.boolean(),
  status: adStatusSchema,
  use_generated_title: z.boolean(),
  offline_date: z.string().datetime({ offset: true }).nullable(),
  type: z.enum(['request', 'offer']),
  image: dataItem(adImageSchema).nullable(),
  profile: dataItem(profileItemSchema),
  location: dataItem(locationItemSchema),
  activities: dataArray(adActivityItemSchema),
  categories: dataArray(adCategoryItemSchema),
  images: dataArray(adImageSchema).nullish(),
});

const adCategoryCountItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  count: z.number(),
  checked: z.boolean(),
});

export type AdStatusType = z.infer<typeof adStatusSchema>;
export type AdItemType = z.infer<typeof adItemSchema>;
export type AdCategoryCountItemType = z.infer<typeof adCategoryCountItemSchema>;
export type AdCategoryItemType = z.infer<typeof adCategoryItemSchema>;
export type AdActivityItemType = z.infer<typeof adActivityItemSchema>;
export type AdImageType = z.infer<typeof adImageSchema>;
export type AdType = AdItemType['type'];

export const distances = [3, 5, 10, 15, 25, 50, 75];

export const sortSlugSchema = z.enum([
  'recent',
  'dichtbij',
  'urgent',
  'relevant',
]);
export const sortKeySchema = z.enum([
  'recent',
  'adjacent',
  'urgent',
  'relevant',
]);
export type SortKey = z.infer<typeof sortKeySchema>;
export type SortSlug = z.infer<typeof sortSlugSchema>;

const sortOptions = {
  recent: 'recent',
  adjacent: 'dichtbij',
  urgent: 'urgent',
  relevant: 'relevant',
} as const;

type SortOptions = typeof sortOptions;

export function getSortKeyBySortSlug(slug: SortSlug): SortKey {
  return (
    objectToEntries(sortOptions).find(([, value]) => value === slug)?.[0] ||
    'recent'
  );
}

export function getSortSlugBySortKey<K extends SortKey>(
  key: K
): SortOptions[K] {
  return sortOptions[key] || 'recent';
}

export type AdFilterType = {
  type?: 'offer' | 'request';
  category_ids?: number[];
  keyword?: string;
  distance?: number;
  postal_code?: number;
  limit?: number;
  page?: number;
  sort?: SortKey;
};

const adsReponseSchema = z.object({
  data: adItemSchema.array(),
  meta: z.object({
    categories: adCategoryCountItemSchema.array(),
    paginator: paginatorSchema,
  }),
});

const adsDataSchema = z.object({
  data: adItemSchema.array(),
  categories: adCategoryCountItemSchema.array(),
  paginator: paginatorSchema,
});

type AdsDataType = z.infer<typeof adsDataSchema>;

export async function getAds({
  type = 'request',
  category_ids = [],
  keyword,
  distance,
  postal_code,
  limit = 16,
  page = 1,
  sort,
  statuses = [],
}: AdFilterType & { statuses?: AdStatusType[] }): Promise<AdsDataType> {
  const {
    data,
    meta: { categories, paginator },
  } = await get(`/market`, adsReponseSchema, {
    type,
    category_ids,
    keyword,
    distance,
    postal_code,
    limit,
    offset: page - 1,
    sort,
    statuses,
  });
  return { data, categories, paginator };
}

export async function getAd({ id }: { id: number }): Promise<AdItemType> {
  const { data } = await get(`/market/${id}`, dataItem(adItemSchema));
  return data;
}

export async function getRelevantAds({
  ad,
  limit = 5,
}: {
  ad: AdItemType;
  limit?: number;
}) {
  const { type, categories, location } = ad;
  const { postal_code } = location.data;

  // Nearest ads in the same category.
  const mostRelevant = await getAds({
    type,
    category_ids: categories.data.map(({ id }) => id),
    postal_code,
    distance: 15,
    limit,
    sort: 'adjacent',
    statuses: ['approved'],
  });

  // Skip additional api calls if we have enough items.
  if (mostRelevant.data.length === limit) {
    return mostRelevant.data.filter(({ id }) => id !== ad.id);
  }

  // Gather additional items.
  const [mostAdjacent, mostUrgent] = await Promise.all([
    // Nearest ads in any category.
    getAds({
      type,
      postal_code,
      distance: 15,
      limit,
      sort: 'adjacent',
      statuses: ['approved'],
    }),

    // Ads that will go offline soon.
    getAds({
      type,
      postal_code,
      distance: 25,
      limit,
      sort: 'urgent',
      statuses: ['approved'],
    }),
  ]);

  const merged = mostRelevant.data
    .concat(mostAdjacent.data)
    .concat(mostUrgent.data)
    .filter(({ id }) => id !== ad.id);

  // Remove duplicates.
  return uniqueBy(merged, (lhs, rhs) => lhs.id === rhs.id);
}

export async function getOwnAds() {
  const auth = authStorage.get('auth', null);
  if (!auth) {
    return Promise.resolve([]);
  }

  const { data } = await get('/account/ads', dataArray(adItemSchema));
  return data;
}

export async function getCategories(): Promise<AdCategoryItemType[]> {
  const { data } = await get(
    `/market-categories`,
    dataArray(adCategoryItemSchema)
  );
  return data;
}

export function reportAd({
  reportReasonId,
  adId,
  description = '',
}: {
  reportReasonId: number;
  adId: number;
  description?: string;
}) {
  return post(
    '/market/report',
    z.object({ id: z.number() }),
    z.object({
      reason: z.enum(['save_failed', 'unauthorized']),
    }),
    { ad_report_reason_id: reportReasonId, ad_id: adId, description }
  );
}

export function removeAd({ id }: AdItemType) {
  return post(
    '/market/remove',
    z.object({ id: z.number() }),
    z.object({
      reason: z.enum(['ad_not_found', 'unauthorized']),
    }),
    { id }
  );
}

export function closeAd({ id }: AdItemType) {
  return post(
    '/market/close',
    z.object({ id: z.number() }),
    z.object({
      reason: z.enum(['ad_not_found', 'unauthorized', 'can_not_close']),
    }),
    { id }
  );
}

export function reopenAd({ id }: AdItemType) {
  return post(
    '/market/reopen',
    z.object({ id: z.number() }),
    z.object({
      reason: z.enum(['ad_not_found', 'unauthorized', 'can_not_reopen']),
    }),
    { id }
  );
}

/* Update ad */

// Utility types for updating ad images.
export type CurrentImageRef = {
  type: 'current';
  image: AdImageType; // Existing image.
};

export type NewImageRef = {
  type: 'new';
  image: ImageUploadType; // Image to upload.
};

export type AdImageRef = CurrentImageRef | NewImageRef;

// Metadata for the backend to describe how to update a set of images.
export type AdImageChange =
  | {
      type: 'current';
      index: number;
      id: number;
    }
  | {
      type: 'new';
      index: number;
    };

export function updateAd({
  title,
  description,
  ad_id,
  location_id,
  postal_code,
  extend_online,
  image_changes = [],
  images = [],
  crop_rects = [],
}: {
  title: string;
  description: string;
  ad_id: number;
  location_id: number;
  postal_code: string;
  extend_online: boolean;
  image_changes: AdImageChange[];
  images: Blob[];
  crop_rects: CropRect[];
}) {
  return post(
    '/market/update',
    z.object({ id: z.number() }),
    z.object({
      reason: z.enum([
        'ad_not_found',
        'location_not_found',
        'no_permission',
        'unauthorized',
      ]),
    }),
    {
      title,
      description,
      ad_id,
      location_id,
      postal_code,
      extend_online: extend_online ? 1 : 0,
      images,
      image_changes,
      crop_rects,
    }
  );
}

/* Create ad */

export function createAd({
  type,
  title,
  description,
  location_id,
  postal_code,
  ad_activity_ids,
  ad_category_ids,
  use_generated_title,
  images,
  crop_rects,
  onUploadProgress,
}: {
  type: AdType;
  title: string;
  description: string;
  location_id: number;
  postal_code: string;
  ad_activity_ids: number[];
  ad_category_ids: number[];
  use_generated_title: boolean;
  images: Blob[];
  crop_rects: CropRect[];
  onUploadProgress: (percentage: number) => void;
}) {
  return post(
    '/market/add',
    z.object({ id: z.number() }),
    z.object({
      reason: z.enum(['unauthorized']),
    }),
    {
      type,
      title,
      description,
      location_id,
      postal_code,
      ad_activity_ids,
      ad_category_ids,
      use_generated_title: use_generated_title ? 1 : 0,
      images,
      crop_rects,
    },
    {},
    {
      onUploadProgress({ loaded, total }) {
        onUploadProgress(
          total && loaded ? Math.round((loaded * 100) / total) : 0
        );
      },
    }
  );
}

export function uploadAvatar({
  image,
  iconId,
  paletteColor,
  onUploadProgress,
}: {
  image?: Blob;
  iconId: number;
  paletteColor: number;
  onUploadProgress: (percentage: number) => void;
}) {
  return post(
    '/account/avatar-upload',
    z.object({ id: z.number() }),
    z.object({
      reason: z.enum(['unauthorized']),
    }),
    {
      icon: iconId,
      color: paletteColor,
      image,
    },
    {},
    {
      onUploadProgress({ loaded, total }) {
        onUploadProgress(
          total && loaded ? Math.round((loaded * 100) / total) : 0
        );
      },
    }
  );
}

/* Selectors */

export function isFavoriteAd(
  { favorites, profile }: AccountStore,
  item: AdItemType
) {
  return profile != null && favorites[item.type]?.includes(item.id);
}

export function isReportedAd({ reportedAds }: AccountStore, item: AdItemType) {
  return reportedAds.some(({ ad_id }) => item.id === ad_id);
}

export function isApprovedAd(ad: AdItemType): boolean {
  return ad.status === 'approved';
}

export function isPublicAd(ad: AdItemType): boolean {
  return (
    ad.is_public && // Ad not hidden
    !ad.is_offline && // Within embargo
    !ad.profile.data.deleted && // Ad owner didn't delete their account
    !ad.profile.data.banned && // Ad owner isn't banned
    (ad.status === 'approved' || ad.status === 'update_review') // Status
  );
}

export function canAccessAd(profile: ProfileItemType | null, ad: AdItemType) {
  if (profile != null && profile.id === ad.profile.data.id) {
    return true;
  }

  return isPublicAd(ad);
}

export function isRejectedAd(ad: AdItemType): boolean {
  return ad.status === 'rejected';
}

export function canFulfillAd(ad: AdItemType) {
  return ['approved', 'fulfilled'].includes(ad.status);
}

export function isFulfilledAd(ad: AdItemType): boolean {
  return ad.status === 'fulfilled';
}

export function getAdStatus(
  profile: ProfileItemType | null,
  ad: AdItemType
): string {
  const isOwnAd = profile && profile.id === ad.profile.data.id;
  const pronoun = isOwnAd ? 'Je' : ad.type === 'offer' ? 'Dit' : 'Deze';
  const label = ad.type === 'offer' ? 'aanbod' : 'vraag';

  if (ad.is_offline) {
    return `${pronoun} ${label} is offline`;
  }

  if (!ad.is_public) {
    return `Niet beschikbaar`;
  }

  switch (ad.status) {
    case 'rejected':
      return `${pronoun} ${label} is afgewezen`;
    case 'in_review':
    case 'update_review':
      return `${pronoun} ${label} wacht op goedkeuring`;
    case 'fulfilled':
      return `${pronoun} ${label} is ingevuld`;
    case 'approved':
      return '';
  }

  return 'Niet beschikbaar';
}

export function getPublicAdStatus(ad: AdItemType) {
  if (ad.is_offline) {
    return 'Offline';
  }

  if (!ad.is_public) {
    return 'Niet beschikbaar';
  }

  switch (ad.status) {
    case 'rejected':
      return 'Afgewezen';
    case 'in_review':
    case 'update_review':
      return 'In review';
    case 'fulfilled':
      return 'Ingevuld';
  }

  return '';
}
