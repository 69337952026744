import NextLink from 'next/link';
import { ReactNode, forwardRef } from 'react';
import { routeToPath, RouteType } from '@/util/routeToPath';
import { useRouter } from 'next/router';
import { Icon, IconType, IconTheme, IconSize } from '@/components/Icon';
import { cx } from '@/util/cx';
import styles from './Link.module.scss';
import { useIsClient } from 'usehooks-ts';

type Props = {
  to: RouteType;
  children: ReactNode;
  className?: string;
  icon?: IconType;
  iconTheme?: IconTheme;
  iconSize?: IconSize;
  iconOnly?: 'mobile' | 'tablet';
  underline?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const Link = forwardRef<HTMLAnchorElement, Props>(function Link(
  {
    to,
    children,
    className,
    icon,
    iconTheme = 1,
    iconSize,
    iconOnly,
    underline,
    ...attributes
  }: Props,
  ref
) {
  const router = useRouter();
  const href = routeToPath(to);
  const isClient = useIsClient();

  return (
    <NextLink
      ref={ref}
      href={href}
      aria-current={
        isClient && router.isReady && href === router.asPath
          ? 'page'
          : undefined
      }
      className={cx(
        className,
        icon && styles['with-icon'],
        underline && styles['with-underline'],
        iconOnly && styles[`icon-only-${iconOnly}`]
      )}
      {...attributes}
    >
      {icon != null && <Icon icon={icon} theme={iconTheme} size={iconSize} />}

      {icon != null ? <span>{children}</span> : children}
    </NextLink>
  );
});
