import z from 'zod';

export const dataItem = <Schema extends z.ZodTypeAny>(data: Schema) =>
  z.object({ data });

export const dataArray = <Schema extends z.ZodTypeAny>(data: Schema) =>
  z.object({ data: data.array() });

export const block = <
  ExternalTable extends z.ZodLiteral<string>,
  Block extends z.ZodTypeAny
>(
  externalTable: ExternalTable,
  block: Block
) =>
  z.object({
    external_table: externalTable,
    order: z.number(),
    block: dataItem(block),
  });

export function is<Schema extends z.ZodTypeAny>(
  schema: Schema,
  object: unknown
): object is z.output<Schema> {
  return schema.safeParse(object).success;
}

export const imageWithCrop = <Schema extends z.ZodTypeAny>(crop: Schema) =>
  z.object({
    id: z.number(),
    h: z.number(),
    s: z.number(),
    l: z.number(),
    crop,
  });

export const imageWithResize = <Schema extends z.ZodTypeAny>(resize: Schema) =>
  z.object({
    id: z.number(),
    h: z.number(),
    s: z.number(),
    l: z.number(),
    resize,
  });
