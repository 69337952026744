export type Responsive<T> =
  | T
  | {
      sm?: T;
      md?: T;
      lg?: T;
      xl?: T;
    };

export function getResponsive<T extends Responsive<string | number>>(
  prefix: string,
  value: T | undefined
): string[] {
  if (value == null) {
    return [];
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return [`${prefix}-${value}`];
  }

  return Object.entries(value)
    .filter(([, v]) => v)
    .map(([breakpoint, val]) => `${prefix}-${breakpoint}-${val}`);
}
