import { AvatarLink } from '@/components/AvatarLink';
import { RawLinkButton } from '@/components/Button';
import { Link } from '@/components/Link';
import { Logo } from '@/components/Logo';
import { logout, useAccount, getNotificationCount } from '@/resource/account';
import styles from './Header.module.scss';

type Props = {
  onRequestNav: () => void;
  navOpen?: boolean;
  inert?: boolean;
};

export function Header({ onRequestNav, navOpen, inert = false }: Props) {
  const state = useAccount();
  const notificationCount = getNotificationCount(state);
  const { profile } = state;

  return (
    <header className={styles.Header} inert={inert ? '' : null}>
      <div>
        <Link to={{ type: 'home' }} title="NH">
          <Logo />
        </Link>
        <Link to={{ type: 'home' }}>NH Helpt</Link>
      </div>

      <nav aria-label="Hoofd navigatie">
        {profile ? (
          <>
            <AvatarLink
              count={notificationCount}
              profile={profile}
              to={{ type: 'account' }}
              title={`Profiel ${profile.first_name}`}
            />
            <button onClick={() => logout()}>Uitloggen</button>
          </>
        ) : (
          <>
            <Link to={{ type: 'login' }}>Login</Link>
            <Link to={{ type: 'register' }}>Registreren</Link>
          </>
        )}

        <RawLinkButton theme="secondary" href="https://www.nhnieuws.nl">
          NH Nieuws
        </RawLinkButton>
      </nav>

      <button
        type="button"
        onClick={onRequestNav}
        aria-pressed={navOpen ? 'true' : 'false'}
      >
        Open navigatie
        {notificationCount > 0 && <span>{notificationCount}</span>}
      </button>
    </header>
  );
}
