import { forwardRef, ReactNode } from 'react';
import styles from './Input.module.scss';
import { cx } from '@/util/cx';

type Props = {
  label?: string;
  error?: string;
  prepend?: ReactNode;
  append?: ReactNode;
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  id: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, Props>(function Input(
  {
    label,
    required,
    type = 'text',
    id,
    prepend,
    append,
    size,
    ...attributes
  }: Props,
  ref
) {
  return (
    <div
      className={cx(
        styles.Input,
        prepend != null && styles['with-prepend'],
        append != null && styles['with-append'],
        size != null && styles[`size-${size}`]
      )}
    >
      {label && (
        <label htmlFor={id}>
          {label} {required && <span>*</span>}
        </label>
      )}

      <div>
        {prepend}

        <input
          id={id}
          {...attributes}
          type={type}
          required={required}
          ref={ref}
        />

        {append}
      </div>

      {/* {error && <Error error={error} />} */}
    </div>
  );
});
