import { cx } from '@/util/cx';
import { Props, getGapModifiers, getColumnWidthModifiers } from './util';

export function Gridish({
  children,
  minColumnWidth = 300,
  gap,
  alignItems,
}: Props) {
  const gapModifiers: string[] = getGapModifiers(gap);
  const widthModifiers: string[] = getColumnWidthModifiers(minColumnWidth);

  return (
    <div
      className={cx(
        'Gridish',
        gapModifiers,
        widthModifiers,
        alignItems && `ai-${alignItems}`
      )}
    >
      {children}
    </div>
  );
}
