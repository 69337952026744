import { ReactNode } from 'react';

type Fr = 0 | 1 | 2 | 3 | 4 | 5; // In rems.

type Gap = Fr | [Fr, Fr];

export type Gaps =
  | Gap
  | {
      sm?: Gap;
      md?: Gap;
      lg?: Gap;
      xl?: Gap;
    };

function getGapModifier(gap: number | number[]): string {
  return typeof gap === 'number' ? String(gap) : gap.join('-');
}

export function getGapModifiers(gap: Gaps | undefined): string[] {
  if (gap == null) {
    return [];
  }

  if (typeof gap === 'number' || Array.isArray(gap)) {
    return [`gap-${getGapModifier(gap)}`];
  }

  return Object.entries(gap)
    .filter(([, v]) => v != null)
    .map(([key, value]) => `gap-${key}-${getGapModifier(value)}`);
}

export function getColumnWidthModifiers(
  widths: ColumnWidths | undefined
): string[] {
  if (widths == null) {
    return [];
  }

  if (typeof widths === 'number') {
    return [`min-${widths}`];
  }

  return Object.entries(widths)
    .filter(([, v]) => v)
    .map(([breakpoint, size]) => `min-${breakpoint}-${size}`);
}

type ColumnWidth =
  | 32
  | 40
  | 48
  | 64
  | 80
  // Larger sizes
  | 100
  | 110
  | 120
  | 130
  | 140
  | 150
  | 160
  | 170
  | 180
  | 190
  | 200
  | 210
  | 220
  | 230
  | 240
  | 250
  | 260
  | 270
  | 280
  | 290
  | 300
  | 310
  | 320
  | 330
  | 340
  | 350
  | 360
  | 370
  | 380
  | 390
  | 400
  | 410
  | 420
  | 430
  | 440;

export type ColumnWidths =
  | ColumnWidth
  | {
      sm?: ColumnWidth;
      md?: ColumnWidth;
      lg?: ColumnWidth;
      xl?: ColumnWidth;
    };

export type Props = {
  children: ReactNode;
  gap?: Gaps;
  minColumnWidth?: ColumnWidths;
  alignItems?: 'start' | 'center' | 'end' | 'stretch';
};
