import { AdImageType } from '@/resource/ad';
import {
  IllustrationNameType,
  MailFrequencyOptionType,
  MailFrequencyType,
} from '@/resource/settings';
import { ImageUploadType } from '@/util/types';
import { ReactNode } from 'react';
import { create } from 'zustand';

type Callback = () => void;

type Context =
  | {
      type: 'login';
      maxWidth?: number;
      props: {
        title: string;
      };
    }
  | {
      type: 'image_gallery';
      maxWidth?: number;
      props: {
        title: string;
        images: AdImageType[];
        initialIndex: number;
      };
    }
  | {
      type: 'message';
      maxWidth?: number;
      props: {
        description: ReactNode;
        illustration?: IllustrationNameType;
        title: string;
      };
    }
  | {
      type: 'confirm';
      maxWidth?: number;
      props: {
        title: string;
        description?: ReactNode;
        onCancel: Callback;
        onConfirm: Callback;
      };
    }
  | {
      type: 'mail_preferences';
      maxWidth?: number;
      props: {
        title: string;
        currentValue: MailFrequencyType;
        options: MailFrequencyOptionType[];
        onCancel: Callback;
        onConfirm: (value: MailFrequencyType) => void;
      };
    }
  | {
      type: 'report';
      maxWidth?: number;
      props: {
        title: string;
        onCancel: Callback;
        onReport: (value: {
          reportReasonId: number;
          description: string;
        }) => void;
      };
    }
  | {
      type: 'image_cropper';
      maxWidth?: number;
      props: {
        file: File;
        title: string;
        aspectRatio: number;
        rounded?: boolean;
        onCancel: Callback;
        maxWidth?: number;
        allowRotation: boolean;
        onSave: (upload: ImageUploadType) => void;
      };
    }
  | {
      type: 'share';
      maxWidth?: number;
      props: {
        title: string;
        data: {
          text: string;
          title: string;
          url: string;
        };
      };
    };

export type ModalProps<T extends Context['type']> = Extract<
  Context,
  { type: T }
>['props'] & { close: Callback; isOpen: boolean };

export type ModalState = {
  onClose?: Callback;
  isOpen: boolean;
  context: Context;
  open: (context: Context, onClose: Callback) => void;
  close: Callback;
};

export const useModal = create<ModalState>((set, get) => ({
  onClose: undefined,
  isOpen: false,
  context: {
    type: 'login',
    props: {
      title: 'Inloggen',
    },
  },
  open(context, onClose) {
    set(
      (state): ModalState => ({
        ...state,
        isOpen: true,
        context,
        onClose,
      })
    );
  },
  close() {
    const { onClose } = get();
    set(
      (state): ModalState => ({
        ...state,
        isOpen: false,
        onClose: undefined,
      })
    );

    // onClose is mostly called to restore focus to
    // the element that opened the modal. We need this
    // to run after the state has been updated to make
    // sure those elements can receive focus again due
    // to toggling of the inert attribute in _app.tsx
    nextTick(() => {
      onClose?.();
    });
  },
}));

function nextTick(callback: () => void) {
  if (typeof queueMicrotask === 'function') {
    return queueMicrotask(callback);
  }

  window.setTimeout(callback, 0);
}
