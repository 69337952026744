import z from 'zod';

export const paginatorSchema = z.object({
  limit: z.number(),
  offset: z.number(),
  count: z.number(),
  total: z.number(),
  last: z.number(),
});

export type PaginatorType = z.infer<typeof paginatorSchema>;
