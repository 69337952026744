import z from 'zod';

const urls = (process.env.NEXT_PUBLIC_LOCAL_URL || '')
  .split(',')
  .filter((x) => x);

export const localUrl = urls[0] || '';
export const localUrls = urls.map((url) => new URL(url));

const envSchema = z.object({
  BASE_URL: z.string().url(),
  LOCAL_URL: z.string().url(),
  LOCAL_URLS: z.instanceof(URL).array(),
  OAUTH2_CLIENT_ID: z.string().min(2),
  OAUTH2_CLIENT_SECRET: z.string().min(2),
  SENTRY_DSN: z.string().optional(),
  SENTRY_ENVIRONMENT: z.string().optional(),
  API_SECRET: z.string(),
  GTAG_ID: z.string().optional(),
  IS_PRODUCTION: z.boolean(),
});

export type EnvType = z.infer<typeof envSchema>;

const env: EnvType = {
  BASE_URL: process.env.NEXT_PUBLIC_BASE_URL || '',
  LOCAL_URL: localUrl,
  LOCAL_URLS: localUrls,
  OAUTH2_CLIENT_ID: process.env.OAUTH2_CLIENT_ID || 'nh',
  OAUTH2_CLIENT_SECRET: process.env.OAUTH2_CLIENT_SECRET || 'nh',
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  GTAG_ID: process.env.NEXT_PUBLIC_GTAG_ID,
  API_SECRET: process.env.NEXT_API_SECRET || '',
  IS_PRODUCTION: process.env.NODE_ENV === 'production',
};

// throws if invalid.
envSchema.parse(env);

export default env;
