import { useState } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

interface WindowSize {
  width: number;
  height: number;
}

function getSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export function useWindowSize(): WindowSize {
  const [size, setSize] = useState({ width: 375, height: 375 });

  useIsomorphicLayoutEffect(() => {
    function handleResize() {
      setSize(getSize());
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
}
