import { Link } from '@/components/Link';
import { ProfileItemType } from '@/resource/profile';
import { RouteType } from '@/util/routeToPath';
import styles from './AvatarLink.module.scss';
import { CSSProperties } from 'react';
import { cx } from '@/util/cx';

type Props = {
  profile: ProfileItemType;
  count: number;
  to: RouteType;
  size?: 'small' | 'medium' | 'large';
} & React.HTMLAttributes<HTMLAnchorElement>;

export function AvatarLink({ profile, count, to, size, ...attr }: Props) {
  const image = profile.image?.data;
  const profileCrop = image?.crop['1:1'];
  const maskImage = `url(/svg/avatar/avatar_${profile.icon_id || 1}.svg)`;
  const iconStyle = {
    maskImage,
    WebkitMaskImage: maskImage,
  } as CSSProperties;

  return (
    <Link
      {...attr}
      to={to}
      className={cx(styles.AvatarLink, size && styles[`size-${size}`])}
      title={profile.first_name}
      style={
        profileCrop
          ? ({
              backgroundColor: `hsl(${image.h}, ${image.s}%, ${image.l}%)`,
              backgroundImage: `url(${
                profileCrop[size === 'large' ? 360 : 120]
              })`,
            } as CSSProperties)
          : ({
              '--background': `var(--palette-${profile.palette_color})`,
            } as CSSProperties)
      }
    >
      {!profileCrop && <b style={iconStyle} />}

      {count > 0 && <span>{count}</span>}
    </Link>
  );
}
