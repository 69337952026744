import { LinkButton } from '@/components/Button';
import { Heading } from '@/components/Heading';
import { Flex } from '@/components/Grid';
import { Text } from '@/components/Text';

export function LoginCta({ title }: { title: string }) {
  return (
    <Flex dir="column" align="center" gap={3} justify="center">
      <Heading balance align="center" visualLevel={3}>
        {title}
      </Heading>
      <Text balance align="center">
        Heb je een account? Log dan in.
      </Text>
      <LinkButton align="center" icon="avatar" to={{ type: 'login' }}>
        Login
      </LinkButton>
      <hr />
      <Heading balance align="center" visualLevel={3}>
        Account aanmaken
      </Heading>
      <Text balance align="center">
        Heb je nog geen account, maar wil je die graag kosteloos aanmaken, klik
        dan hieronder.
      </Text>
      <LinkButton align="center" theme="secondary" to={{ type: 'register' }}>
        Registreren
      </LinkButton>
    </Flex>
  );
}
