import styles from './IconButton.module.scss';
import { cx } from '@/util/cx';
import { IconType, Icon, IconSize } from '@/components/Icon';
import { forwardRef } from 'react';

type SharedProps = {
  icon: IconType;
  iconSize?: IconSize;
  theme?: 'primary' | 'secondary' | 'compact';
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  title: string;
};

type Props = SharedProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  function IconButton(
    {
      type = 'button',
      icon,
      iconSize,
      theme,
      size,
      title,
      onClick,
      ...attributes
    },
    ref
  ) {
    return (
      <button
        ref={ref}
        title={title}
        onClick={onClick}
        type={type}
        className={cx(
          styles.Button,
          theme != null && styles[`theme-${theme}`],
          size != null && styles[`size-${size}`]
        )}
        {...attributes}
      >
        {icon && (
          <Icon
            icon={icon}
            size={iconSize}
            theme={theme === 'secondary' || theme === 'compact' ? 1 : 0}
          />
        )}
      </button>
    );
  }
);

type LinkProps = SharedProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const RawIconLink = forwardRef<HTMLAnchorElement, LinkProps>(
  function RawIconLink(
    { icon, iconSize, theme, size, title, href, ...attributes },
    ref
  ) {
    return (
      <a
        ref={ref}
        title={title}
        href={href}
        className={cx(
          styles.Button,
          theme != null && styles[`theme-${theme}`],
          size != null && styles[`size-${size}`]
        )}
        {...attributes}
      >
        {icon && (
          <Icon
            icon={icon}
            size={iconSize}
            theme={theme === 'secondary' || theme === 'compact' ? 1 : 0}
          />
        )}
      </a>
    );
  }
);
