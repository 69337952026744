import styles from './Logo.module.scss';

export function Logo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={styles.Logo}
    >
      <path
        d="M30.164 48H45.6C45.9152 48 46.2273 47.9379 46.5184 47.8173C46.8096 47.6967 47.0742 47.5199 47.2971 47.2971C47.5199 47.0742 47.6967 46.8096 47.8173 46.5184C47.9379 46.2273 48 45.9152 48 45.6V2.4C48 2.08483 47.9379 1.77274 47.8173 1.48156C47.6967 1.19038 47.5199 0.925802 47.2971 0.702942C47.0742 0.480081 46.8096 0.303297 46.5184 0.182686C46.2273 0.0620746 45.9152 0 45.6 0H31.2C30.5635 0 29.953 0.252855 29.5029 0.702942C29.0529 1.15303 28.8 1.76348 28.8 2.4V9.6L22.338 1.865C21.1444 0.671519 19.5259 0.00071898 17.838 0H2.4C2.08483 0 1.77274 0.0620746 1.48156 0.182686C1.19038 0.303297 0.925802 0.480081 0.702942 0.702942C0.480081 0.925802 0.303297 1.19038 0.182686 1.48156C0.0620746 1.77274 0 2.08483 0 2.4V45.6C0 46.2365 0.252855 46.847 0.702942 47.2971C1.15303 47.7471 1.76348 48 2.4 48H16.8C17.4365 48 18.047 47.7471 18.4971 47.2971C18.9471 46.847 19.2 46.2365 19.2 45.6V38.4L25.662 46.135C26.856 47.329 28.4754 47.9998 30.164 48V48Z"
        fill="url(#nh_logo_gradient)"
        className={styles.LogoN}
      />
      <path
        d="M28.806 9.60001V19.2H19.194V11.04C19.194 10.6581 19.0423 10.2918 18.7722 10.0218C18.5022 9.75172 18.1359 9.60001 17.754 9.60001H10.08C9.69809 9.60001 9.33182 9.75172 9.06177 10.0218C8.79172 10.2918 8.64 10.6581 8.64 11.04V36.96C8.64 37.3419 8.79172 37.7082 9.06177 37.9782C9.33182 38.2483 9.69809 38.4 10.08 38.4H19.194V28.8H28.806V36.96C28.806 37.3419 28.9577 37.7082 29.2278 37.9782C29.4978 38.2483 29.8641 38.4 30.246 38.4H37.92C38.3019 38.4 38.6682 38.2483 38.9382 37.9782C39.2083 37.7082 39.36 37.3419 39.36 36.96V11.04C39.36 10.6581 39.2083 10.2918 38.9382 10.0218C38.6682 9.75172 38.3019 9.60001 37.92 9.60001H28.806Z"
        fill="white"
        className={styles.LogoH}
      />
      <defs>
        <linearGradient
          id="nh_logo_gradient"
          x1="0"
          y1="24"
          x2="48"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0017A0"></stop>
          <stop offset="1" stopColor="#41A5F7"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
