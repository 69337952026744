import z from 'zod';
import { post } from '../util/api';
import { dataItem, imageWithCrop } from './helpers';
import { locationItemSchema } from './location';
import { mailFrequencySchema } from './mail';

export const profileImageSchema = imageWithCrop(
  z.object({
    '1:1': z.object({
      120: z.string(),
      240: z.string(),
      360: z.string(),
    }),
  })
);

export const profileItemSchema = z.object({
  id: z.number(),
  palette_color: z.number(),
  icon_id: z.number().nullable(),
  first_name: z.string().min(1),
  slug: z.string().min(1),
  image: dataItem(profileImageSchema).nullable().optional(),
  location: dataItem(locationItemSchema).nullable().optional(),
  deactivated: z.boolean(),
  banned: z.boolean(),
  deleted: z.boolean(),
});

export const privateProfileItemSchema = profileItemSchema.merge(
  z.object({
    last_name: z.string(),
    email: z.string(),
    phone: z.string().nullable(),
    allow_contact: z.boolean(),
    auth_type: z.enum(['password', 'google', 'facebook']),
    has_password: z.boolean(),
    ad_mail_frequency: mailFrequencySchema,
    chat_mail_frequency: mailFrequencySchema,
  })
);

export type ProfileItemType = z.infer<typeof profileItemSchema>;

export type PrivateProfileItemType = z.infer<typeof privateProfileItemSchema>;

export async function blockProfile({ id }: ProfileItemType) {
  return await post(
    `/profiles/block`,
    z.any(),
    z.object({
      reason: z.enum(['unauthorized']),
    }),
    { blocked_profile_id: id }
  );
}

export async function unblockProfile({ id }: ProfileItemType) {
  return await post(
    `/profiles/unblock`,
    z.any(),
    z.object({
      reason: z.enum(['unauthorized']),
    }),
    { blocked_profile_id: id }
  );
}

// After registration with Facebook or Google we still have some missing fields.
// This does some additional checks on top of the schema validation.
export function isProfileComplete(profile: unknown): boolean {
  const parsed = privateProfileItemSchema.safeParse(profile);
  if (!parsed.success) {
    return false;
  }

  const { data } = parsed;
  return (
    data.last_name.length > 0 &&
    data.location?.data != null &&
    (data.icon_id != null || data.image?.data != null)
  );
}
