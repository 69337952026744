import { Button } from '@/components/Button';
import { Flex } from '@/components/Grid';
import useResizeObserver from '@/hooks/useResizeObserver';
import { clamp } from '@/util/clamp';
import { downsizeCanvas, fixOrientation } from '@/util/resizeCanvas';
import { ImageUploadType } from '@/util/types';
import { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

type Props = {
  original: File;
  onSave: (upload: ImageUploadType) => void;
  aspectRatio: number;
  allowRotation?: boolean;
  rounded?: boolean;
  maxHeight?: number;
  maxWidth?: number;
};

export function ImageCropper({
  original,
  onSave,
  aspectRatio = 1,
  rounded = false,
  allowRotation = false,
  maxHeight = 2400,
  maxWidth = 2400,
}: Props) {
  const editorRef = useRef<AvatarEditor>(null);
  const [backgroundColor, setBackgroundColor] = useState('#b5c9db');
  const [resizing, setResizing] = useState(false);
  const [ready, setReady] = useState(false);
  const disabled = !ready;
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const increment = 0.1;
  const minScale = 1;
  const maxScale = 2;

  const [width, setWidth] = useState(240);
  const height = width / aspectRatio;
  const borderRadius = width < height ? width : height;
  const border = width < 400 ? 4 : 8;
  const offset = border * 2;

  const ref = useResizeObserver((el, entry) => {
    setWidth(entry.contentRect.width);
  });

  return (
    <Flex dir="column" gap={3}>
      <Flex dir="column" align="center" ref={ref}>
        <AvatarEditor
          style={{
            backgroundColor,
            borderRadius: rounded ? borderRadius : 0,
          }}
          key={`${original.name}_${original.size}`}
          ref={editorRef}
          image={original}
          width={width - offset}
          height={height - offset}
          border={border}
          color={[255, 255, 255, 0.6]}
          borderRadius={rounded ? borderRadius : 0}
          scale={scale}
          rotate={rotation}
          backgroundColor={backgroundColor}
          onImageReady={() => {
            setReady(true);
            const context = editorRef.current?.getImage().getContext('2d');
            if (context) {
              const [r, g, b] = Array.from(
                context.getImageData(10, 10, 1, 1).data
              );
              setBackgroundColor(`rgb(${r}, ${g}, ${b})`);
            }
          }}
        />
      </Flex>

      <Flex justify="space-between">
        <Flex>
          <Button
            iconOnly="tablet"
            icon="circle_minus"
            theme="secondary"
            disabled={disabled || scale === minScale}
            onClick={() =>
              setScale((value) => clamp(value - increment, minScale, maxScale))
            }
          >
            Verkleinen
          </Button>
          <Button
            iconOnly="tablet"
            icon="circle_plus"
            theme="secondary"
            disabled={disabled || scale === maxScale}
            onClick={() =>
              setScale((value) => clamp(value + increment, minScale, maxScale))
            }
          >
            Vergroten
          </Button>

          {allowRotation && (
            <Button
              iconOnly="tablet"
              icon="rotate_right"
              theme="secondary"
              disabled={disabled}
              onClick={() => setRotation((value) => (value + 90) % 360)}
            >
              Draaien
            </Button>
          )}
        </Flex>
        <Flex justify="end">
          <Button
            align="right"
            disabled={disabled}
            loading={resizing}
            onClick={() => {
              const editor = editorRef.current;

              if (editor) {
                setResizing(true);
                const rect = editor.getCroppingRect();
                Promise.all([
                  fixOrientation(original, {
                    maxHeight,
                    maxWidth,
                  }),
                  downsizeCanvas(editor.getImage(), {
                    maxHeight,
                    maxWidth,
                  }),
                ])
                  .then(([original, crop]) =>
                    onSave({
                      crop,
                      original,
                      rect: {
                        ...rect,
                        scale,
                        rotate: rotation,
                      },
                    })
                  )
                  .catch((error) => console.error(error))
                  .finally(() => setResizing(false));
              }
            }}
          >
            Ok!
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
