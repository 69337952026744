import {
  type AdItemType,
  type AdFilterType,
  getSortSlugBySortKey,
} from '@/resource/ad';
import { type PageRefType } from '@/resource/settings';
import { encodePathSegment } from './encodePath';

type Param = string | number;
type RouteParams = Param[];

export type RouteType =
  | { type: 'account' }
  | { type: 'add_offer' }
  | { type: 'add_request' }
  | { type: 'conversations' }
  | { type: 'home' }
  | { type: 'contact' }
  | { type: 'nh_chat' }
  | { type: 'info'; page: PageRefType }
  | { type: 'login' }
  | { type: 'ad_detail'; ad: AdItemType }
  | { type: 'market_offers'; filter?: AdFilterType }
  | { type: 'market_requests'; filter?: AdFilterType }
  | { type: 'profile_ads' }
  | { type: 'profile_info' }
  | { type: 'profile_security' }
  | { type: 'profile_delete' }
  | { type: 'profile_status' }
  | { type: 'profile_preferences' }
  | { type: 'forgot_password' }
  | { type: 'register' }
  | { type: 'saved_ads' }
  | { type: 'saved_searches' }
  | { type: 'start_conversation'; ad: AdItemType }
  | { type: 'system_notifications' }
  | { type: 'update_ad'; ad: AdItemType };

function getFilterParams(
  root: 'vraag' | 'aanbod',
  filter: AdFilterType
): RouteParams {
  const {
    category_ids,
    distance = -1,
    keyword,
    page = 1,
    postal_code,
    sort,
  } = filter;

  const params: RouteParams = [root];

  if (postal_code) {
    if (distance > 0) {
      params.push('afstand', distance, 'vanaf', postal_code);
    } else {
      params.push('vanaf', postal_code);
    }
  }

  if (category_ids?.length) {
    params.push('categorie', category_ids.join('.'));
  }

  if (page > 1) {
    params.push('p', page);
  }

  if (sort?.length && sort !== 'recent') {
    params.push('meest', getSortSlugBySortKey(sort));
  }

  const search = encodePathSegment(keyword || '');
  if (search.length > 1) {
    params.push('zoek', search);
  }

  return params;
}

const accountRoot = 'account';
const offerRoot = 'aanbod';
const requestRoot = 'vraag';
const adDetail = 'van';
const adCreate = 'aanmaken';
const adUpdate = 'bewerken';

function toPath(route: RouteType): RouteParams {
  switch (route.type) {
    case 'home':
      return [];

    case 'ad_detail': {
      const { ad } = route;
      return [
        ad.type === 'offer' ? offerRoot : requestRoot,
        adDetail,
        ad.profile.data.slug,
        ad.id,
      ];
    }

    case 'start_conversation': {
      const { ad } = route;
      return ['bericht', 'aan', ad.profile.data.slug, ad.id];
    }

    case 'market_requests': {
      const { filter } = route;
      if (!filter) {
        return [requestRoot];
      }
      return getFilterParams(requestRoot, filter);
    }

    case 'market_offers': {
      const { filter } = route;
      if (!filter) {
        return [offerRoot];
      }
      return getFilterParams(offerRoot, filter);
    }

    case 'add_offer': {
      return [offerRoot, adCreate];
    }

    case 'add_request': {
      return [requestRoot, adCreate];
    }

    case 'update_ad': {
      const { id, profile, type } = route.ad;

      return [
        type === 'offer' ? offerRoot : requestRoot,
        adUpdate,
        profile.data.slug,
        id,
      ];
    }

    case 'info':
      return ['info', route.page.slug];

    case 'login':
      return ['login'];

    case 'register':
      return ['registreren'];

    case 'account':
      return [accountRoot];

    case 'saved_ads':
      return [accountRoot, 'favorieten'];

    case 'saved_searches':
      return [accountRoot, 'zoekopdrachten'];

    case 'system_notifications':
      return [accountRoot, 'systeemmeldingen'];

    case 'profile_info':
      return [accountRoot, 'gegevens'];

    case 'profile_security':
      return [accountRoot, 'beveiliging'];

    case 'profile_ads':
      return [accountRoot, 'vraag-en-aanbod'];

    case 'conversations':
      return [accountRoot, 'berichten'];

    case 'nh_chat':
      return [accountRoot, 'contact'];

    case 'forgot_password':
      return ['wachtwoord-vergeten'];

    case 'profile_delete':
      return [accountRoot, 'opzeggen'];

    case 'profile_status':
      return [accountRoot, 'status'];

    case 'profile_preferences':
      return [accountRoot, 'voorkeuren'];

    case 'contact':
      return ['contact'];
  }

  route satisfies never;
  return [];
}

function joinPath(path: Param[]): string {
  return `/${path
    .join('/')
    .split('/')
    .filter((x) => x)
    .join('/')}`;
}

export function routeToPath(route: RouteType): string {
  return joinPath(toPath(route));
}
