import { ReactNode } from 'react';
import { cx } from '@/util/cx';
import styles from './Heading.module.scss';

type Level = 1 | 2 | 3 | 4 | 5 | 6;

type Props = {
  level?: Level;
  visualLevel?: Level;
  align?: 'center' | 'right';
  balance?: boolean;
  children: ReactNode;
  clamp?: 1 | 2 | 3;
  onClick?: () => void;
};

type Tag = keyof JSX.IntrinsicElements;

export function Heading({
  level = 2,
  visualLevel,
  balance,
  children,
  align,
  clamp,
  onClick,
}: Props) {
  const Tag = `h${level}` as Tag;

  return (
    <Tag
      role={onClick ? 'button' : undefined}
      onClick={onClick}
      className={cx(
        styles.H,
        styles[`h${visualLevel || level}`],
        align && styles[`align-${align}`],
        clamp && styles[`clamp-${clamp}`],
        balance && styles.balance
      )}
    >
      {children}
    </Tag>
  );
}
