import { ReactNode } from 'react';

type Tag = keyof JSX.IntrinsicElements;

type Props = {
  children: ReactNode;
  tag?: Tag;
};

export function InlineFieldGroup({ children, tag = 'div' }: Props) {
  const Tag = tag;
  return <Tag className="InlineFieldGroup">{children}</Tag>;
}
