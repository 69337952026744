import { cx } from '@/util/cx';
import { ReactNode } from 'react';
import { type Gaps, getGapModifiers } from './util';

// Must match the $sets variable in the scss.
type ColumnSet =
  | [4]
  | [6]
  | [7]
  | [8]
  | [10]
  | [11]
  | [12]
  | [1, 11]
  | [2, 10]
  | [10, 1]
  | [10, 2]
  | [3, 5]
  | [3, 7]
  | [3, 9]
  | [4, 4]
  | [4, 6]
  | [4, 8]
  | [5, 7]
  | [6, 3]
  | [6, 6]
  | [9, 3]
  | [8, 4]
  | [8, 3]
  | [8, 2]
  | [7, 5]
  | [7, 3]
  | [1, 10, 1]
  | [1, 8, 3]
  | [2, 6, 4]
  | [2, 7, 3]
  | [2, 8, 2]
  | [3, 5, 2]
  | [3, 5, 4]
  | [3, 6, 3]
  | [4, 4, 4]
  | [4, 3, 5]
  | [5, 2, 5]
  | [5, 3, 4]
  | [5, 5, 1]
  | [2, 4, 4, 2]
  | [2, 3, 5, 2]
  | [2, 3, 3, 4]
  | [1, 5, 5, 1]
  | [1, 6, 4, 1];

export type Offsets = number | { md?: number; lg?: number; xl?: number };
export type ColumnSets =
  | ColumnSet
  | { md?: ColumnSet; lg?: ColumnSet; xl?: ColumnSet };

type Props = {
  children: ReactNode;
  columns: ColumnSets;
  offsets?: Offsets;
  alignItems?: 'start' | 'center' | 'end' | 'stretch';
  gap?: Gaps;
};

function getOffset(offsets: Offsets) {
  let offset = 0;
  let md = 0;
  let lg = 0;
  let xl = 0;
  if (typeof offsets === 'number') {
    offset = offsets;
  } else {
    md = offsets.md || 0;
    lg = offsets.lg || 0;
    xl = offsets.xl || 0;
  }

  return { offset, md, lg, xl };
}

function getColumnSets(columns: ColumnSets) {
  let md: ColumnSet | undefined = undefined;
  let lg: ColumnSet | undefined = undefined;
  let xl: ColumnSet | undefined = undefined;
  let grid: ColumnSet | undefined = undefined;

  if (Array.isArray(columns)) {
    grid = columns;
  } else {
    md = columns.md;
    lg = columns.lg;
    xl = columns.xl;
  }

  return { grid, md, lg, xl };
}

export function Grid({
  children,
  columns,
  offsets = 0,
  alignItems,
  gap,
}: Props) {
  const { grid, md, lg, xl } = getColumnSets(columns);
  const offset = getOffset(offsets);
  const gapModifiers: string[] = getGapModifiers(gap);

  return (
    <div
      className={cx(
        'Grid',

        // Columns
        grid && `col-${grid.join('-')}`,
        md && 'col-md',
        md && `col-md-${md.join('-')}`,
        lg && 'col-lg',
        lg && `col-lg-${lg.join('-')}`,
        xl && 'col-xl',
        xl && `col-xl-${xl.join('-')}`,

        // Offsets
        offset.offset > 0 && `offset-${offset.offset}`,
        offset.md > 0 && `offset-md-${offset.md}`,
        offset.lg > 0 && `offset-lg-${offset.lg}`,
        offset.xl > 0 && `offset-xl-${offset.xl}`,

        alignItems && `ai-${alignItems}`,

        gapModifiers
      )}
    >
      {children}
    </div>
  );
}
