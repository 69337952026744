import z from 'zod';
import { dataItem, dataArray } from './helpers';
import { get } from '../util/api';

export const provinceItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  active: z.boolean(),
});

export const locationItemSchema = z.object({
  id: z.number(),
  place: z.string(),
  postal_code: z.number(),
  latitude: z.number(),
  longitude: z.number(),
  province: dataItem(provinceItemSchema).optional().nullable(),
});

export type LocationItemType = z.infer<typeof locationItemSchema>;

export async function getLocations({
  postalCode,
}: {
  postalCode: number;
}): Promise<LocationItemType[]> {
  const { data } = await get(
    `/location/${postalCode}`,
    dataArray(locationItemSchema)
  );
  return data;
}
