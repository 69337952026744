import z from 'zod';

function createStorage<
  ItemType extends z.ZodTypeAny,
  ItemTypes extends Record<string, ItemType>
>(storage: Storage, storageItemTypes: ItemTypes) {
  function get<K extends Extract<keyof ItemTypes, string>>(
    key: K,
    defaultValue: z.output<ItemTypes[K]>
  ): z.output<ItemTypes[K]> {
    const item = storage.getItem(key);

    if (item == null) {
      return defaultValue;
    }

    try {
      const schema = storageItemTypes[key];
      const parsed = schema.safeParse(JSON.parse(item));
      return parsed.success ? parsed.data : defaultValue;
    } catch {
      // Ignore. Likely security error or JSON error.
    }

    return defaultValue;
  }

  function set<K extends Extract<keyof ItemTypes, string>>(
    key: K,
    value: z.output<ItemTypes[K]>
  ): z.output<ItemTypes[K]> {
    try {
      storage.setItem(key, JSON.stringify(value));
    } catch {
      // Ignore. Likely security error.
    }

    return value;
  }

  function clear() {
    storage.clear();
  }

  function remove<K extends Extract<keyof ItemTypes, string>>(key: K) {
    storage.removeItem(key);
  }

  return { get, set, clear, remove };
}

// Stub.
const ssrStorage: Storage = {
  length: 0,
  clear() {
    //
  },
  getItem() {
    return null;
  },
  key() {
    return null;
  },
  removeItem() {
    //
  },
  setItem() {
    //
  },
};

export function createLocalStorage<
  ItemType extends z.ZodTypeAny,
  ItemTypes extends Record<string, ItemType>
>(storageItemTypes: ItemTypes) {
  return createStorage(
    typeof window !== 'object' ? ssrStorage : window.localStorage,
    storageItemTypes
  );
}

export function createSessionStorage<
  ItemType extends z.ZodTypeAny,
  ItemTypes extends Record<string, ItemType>
>(storageItemTypes: ItemTypes) {
  return createStorage(
    typeof window !== 'object' ? ssrStorage : window.sessionStorage,
    storageItemTypes
  );
}
