import { forwardRef } from 'react';
import styles from './Textarea.module.scss';
import { cx } from '@/util/cx';

type Props = {
  label?: string;
  error?: string;
  id: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(function Input(
  { label, required, id, ...attributes }: Props,
  ref
) {
  return (
    <div className={cx(styles.Textarea)}>
      {label && (
        <label htmlFor={id}>
          {label} {required && <span>*</span>}
        </label>
      )}

      <div>
        <textarea id={id} required={required} ref={ref} {...attributes} />
      </div>
    </div>
  );
});
