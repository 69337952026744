import { Link } from '@/components/Link';
import { Logo } from '@/components/Logo';
import { PageRefType } from '@/resource/settings';
import styles from './Footer.module.scss';

type Props = {
  pages: PageRefType[];
  inert?: boolean;
};

export function Footer({ pages, inert = false }: Props) {
  return (
    <footer className={styles.Footer} inert={inert ? '' : null}>
      <Logo />
      <div className={styles.FooterInner}>
        <div>
          <h2>NH Algemeen</h2>
          <nav aria-label="Algemeen">
            {pages.map((page) => (
              <Link to={{ type: 'info', page }} key={page.id}>
                {page.title}
              </Link>
            ))}
            <Link to={{ type: 'contact' }}>Contact</Link>
          </nav>
        </div>
        <div>
          <h2>NH Helpt</h2>
          <nav aria-label="Vraag & aanbod">
            <Link to={{ type: 'market_offers' }}>Bekijk alle aanbod</Link>
            <Link to={{ type: 'market_requests' }}>Bekijk alle vraag</Link>
            <Link to={{ type: 'add_offer' }}>Plaats aanbod</Link>
            <Link to={{ type: 'add_request' }}>Plaats vraag</Link>
          </nav>
        </div>
      </div>
    </footer>
  );
}
