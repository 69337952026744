import z from 'zod';

// When adding an icon here, also update the css and ad_categories module definition.
export const iconSchema = z.enum([
  'ad',
  'answer',
  'arrow_left',
  'avatar',
  'ban',
  'bell',
  'book',
  'broom',
  'calendar',
  'camera',
  'car',
  'care',
  'cat',
  'check_thin',
  'check',
  'chevron_down',
  'chevron_left_narrow',
  'chevron_right_narrow',
  'chevron_up',
  'circle_facebook',
  'circle_minus',
  'circle_plus',
  'cleaning',
  'clipboard_check',
  'clipboard',
  'clock',
  'close_thin',
  'coffee',
  'computer',
  'crop',
  'delivered',
  'dog',
  'dots',
  'download_circle',
  'duo',
  'eye_closed',
  'eye',
  'facebook',
  'filter',
  'find',
  'folder',
  'food',
  'friend',
  'gardening',
  'gear',
  'glasses',
  'google',
  'heart_fill',
  'heart_outline',
  'help',
  'hobby',
  'image_slash',
  'info',
  'key',
  'linkedin',
  'location',
  'mail',
  'music_note',
  'pen',
  'phone_video',
  'photo',
  'rotate_right',
  'search',
  'seen',
  'share',
  'shopping_cart',
  'sport',
  'star',
  'tools',
  'trash',
  'triangle_up',
  'upload',
  'video',
  'warning',
  'whatsapp',
  'x',
]);
