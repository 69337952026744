import z from 'zod';
import * as Sentry from '@sentry/nextjs';
import env from '@/util/env';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { authStorage } from '../resource/auth';
import { SerializableValue } from '@/util/types';

const { BASE_URL, IS_PRODUCTION } = env;

type Params = Record<
  string,
  string | number | number[] | string[] | null | undefined
>;

type Body = Record<
  string,
  SerializableValue | Blob | Blob[] | File | File[] | undefined
>;

const abc = axios.create({
  baseURL: BASE_URL,
});

abc.interceptors.request.use(
  (config) => {
    const auth = authStorage.get('auth', null);
    if (auth?.access_token) {
      config.headers.set('Authorization', `Bearer ${auth.access_token}`);
    }
    return config;
  },

  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

abc.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response && error.response.status !== 404) {
      Sentry.captureException(error);
    }

    return Promise.reject(error);
  }
);

export function get<Schema extends z.ZodTypeAny>(
  path: string,
  schema: Schema,
  params: Params = {}
): Promise<z.output<Schema>> {
  return abc
    .get(path, { params })
    .then(({ data }) => (IS_PRODUCTION ? data : schema.parse(data)));
}

type Result<OkType, CatchType> =
  | { type: 'ok'; data: OkType }
  | { type: 'catch'; data: CatchType }
  | { type: 'error'; data: unknown };

export async function post<
  OkSchema extends z.ZodTypeAny,
  CatchSchema extends z.ZodTypeAny
>(
  path: string,
  okSchema: OkSchema,
  catchSchema: CatchSchema,
  body: FormData | Body,
  params: Params = {},
  config: AxiosRequestConfig = {}
): Promise<Result<z.output<OkSchema>, z.output<CatchSchema>>> {
  try {
    const { data } = await abc.post(path, body, {
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    });

    return {
      type: 'ok',
      data: IS_PRODUCTION ? data : okSchema.parse(data),
    };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data) {
      return {
        type: 'catch',
        data: IS_PRODUCTION
          ? error.response.data
          : catchSchema.parse(error.response.data),
      };
    }

    return {
      type: 'error',
      data: error,
    };
  }
}

// For posting to nextjs api routes.
const local = axios.create({
  baseURL: '/',
});

export function localPost<Schema extends z.ZodTypeAny>(
  path: string,
  schema: Schema,
  body: FormData | Body,
  params: Params = {}
): Promise<z.output<Schema>> {
  return local
    .post(path, body, {
      params,
    })
    .then(({ data }) => (IS_PRODUCTION ? data : schema.parse(data)));
}
