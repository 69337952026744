import { AvatarLink } from '@/components/AvatarLink';
import { Button, LinkButton, RawLinkButton } from '@/components/Button';
import { Link } from '@/components/Link';
import { Logo } from '@/components/Logo';
import { logout, useAccount, getNotificationCount } from '@/resource/account';
import styles from './Nav.module.scss';

type Props = {
  open?: boolean;
  onRequestClose: () => void;
};

export function Nav({ open = false, onRequestClose }: Props) {
  const state = useAccount();
  const notificationCount = getNotificationCount(state);
  const { profile } = state;

  return (
    <div className={styles.Nav} inert={!open ? '' : undefined}>
      <div className={styles.NavInner}>
        <div>
          <Link to={{ type: 'home' }} title="NH">
            <Logo />
          </Link>
          <button onClick={onRequestClose}>Sluit navigatie</button>
        </div>

        {profile && (
          <AvatarLink
            count={notificationCount}
            size="large"
            profile={profile}
            to={{ type: 'account' }}
            title={`Profiel ${profile.first_name}`}
          />
        )}

        <nav>
          {profile ? (
            <>
              <LinkButton theme="secondary" to={{ type: 'account' }}>
                Jouw account
              </LinkButton>
              <LinkButton theme="secondary" to={{ type: 'home' }}>
                Home
              </LinkButton>
            </>
          ) : (
            <>
              <LinkButton to={{ type: 'login' }}>Login</LinkButton>
              <LinkButton to={{ type: 'register' }}>Registreren</LinkButton>
              <LinkButton theme="secondary" to={{ type: 'home' }}>
                Home
              </LinkButton>
            </>
          )}

          <hr />

          <RawLinkButton theme="outline" href="https://www.nhnieuws.nl">
            NH Nieuws
          </RawLinkButton>

          {profile && (
            <Button theme="outline" onClick={logout}>
              Uitloggen
            </Button>
          )}
        </nav>
      </div>
    </div>
  );
}
