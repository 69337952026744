// https://github.com/sindresorhus/ts-extras/blob/main/source/object-from-entries.ts
export const objectFromEntries = Object.fromEntries as <
  Key extends PropertyKey,
  Entries extends ReadonlyArray<readonly [Key, unknown]>
>(
  values: Entries
) => {
  [K in Extract<Entries[number], readonly [Key, unknown]>[0]]: Extract<
    Entries[number],
    readonly [K, unknown]
  >[1];
};

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const objectToEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;
