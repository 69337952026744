import {
  Icon,
  type IconType,
  type IconSize,
  type IconTheme,
} from '@/components/Icon';
import { Link } from '@/components/Link';
import { cx } from '@/util/cx';
import { isExternalUrl } from '@/util/isExternalUrl';
import { RouteType } from '@/util/routeToPath';
import NextLink from 'next/link';
import { ReactNode, useRef } from 'react';
import styles from './Button.module.scss';

type StyleProps = {
  theme?: 'primary' | 'secondary' | 'outline' | 'warn';
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
  align?: 'left' | 'center' | 'right';
  shape?: 'round' | 'square';
  icon?: IconType;
  iconOnly?: 'mobile' | 'tablet';
  iconPosition?: 'right';
  iconSize?: IconSize;
  iconTheme?: IconTheme;
  badge?: number;
};

type SharedProps<E extends HTMLElement> = StyleProps & {
  children: ReactNode;
  onClick?: (e: React.MouseEvent<E, MouseEvent>, refocus: () => void) => void;
};

type ButtonProps = SharedProps<HTMLButtonElement> &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

export function Button({
  type = 'button',
  theme = 'primary',
  shape,
  size,
  icon,
  iconOnly,
  iconTheme,
  children,
  loading,
  align,
  onClick,
  iconPosition,
  iconSize,
  badge = 0,
  ...attributes
}: ButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const refocus = () => ref.current?.focus();

  return (
    <button
      ref={ref}
      type={type}
      onClick={onClick ? (e) => onClick(e, refocus) : undefined}
      className={cx(
        styles.Button,
        size && styles[`size-${[size]}`],
        theme && styles[`theme-${[theme]}`],
        align && styles[`align-${[align]}`],
        shape && styles[`shape-${[shape]}`],
        iconOnly && styles[`icon-only-${[iconOnly]}`],
        iconPosition && styles[`icon-position-${iconPosition}`],
        loading && styles.loading
      )}
      {...attributes}
    >
      {icon && (
        <Icon
          icon={icon}
          theme={iconTheme != null ? iconTheme : theme !== 'primary' ? 1 : 0}
          size={iconSize}
        />
      )}

      <span className={styles.Label}>{children}</span>

      {badge > 0 && <span className={styles.Badge}>{badge}</span>}
    </button>
  );
}

type LinkButtonProps = SharedProps<HTMLAnchorElement> &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'> & {
    to: RouteType;
  };

export function LinkButton({
  to,
  theme = 'primary',
  shape,
  size,
  iconOnly,
  iconTheme,
  children,
  icon,
  align,
  onClick,
  iconPosition,
  iconSize,
  badge = 0,
  ...attributes
}: LinkButtonProps) {
  const ref = useRef<HTMLAnchorElement>(null);
  const refocus = () => ref.current?.focus();

  return (
    <Link
      to={to}
      ref={ref}
      onClick={onClick ? (e) => onClick(e, refocus) : undefined}
      className={cx(
        styles.Button,
        size && styles[`size-${[size]}`],
        theme && styles[`theme-${[theme]}`],
        align && styles[`align-${[align]}`],
        shape && styles[`shape-${[shape]}`],
        iconPosition && styles[`icon-position-${iconPosition}`],
        iconOnly && styles[`icon-only-${[iconOnly]}`]
      )}
      {...attributes}
    >
      {icon && (
        <Icon
          icon={icon}
          theme={iconTheme != null ? iconTheme : theme !== 'primary' ? 1 : 0}
          size={iconSize}
        />
      )}

      <span className={styles.Label}>{children}</span>

      {badge > 0 && <span className={styles.Badge}>{badge}</span>}
    </Link>
  );
}

type RawLinkButtonProps = SharedProps<HTMLAnchorElement> &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'> & {
    href: string;
  };

export function RawLinkButton({
  theme = 'primary',
  shape,
  size,
  iconOnly,
  iconTheme,
  children,
  icon,
  align,
  href,
  onClick,
  iconPosition,
  iconSize,
  target,
  ...attributes
}: RawLinkButtonProps) {
  const ref = useRef<HTMLAnchorElement>(null);
  const refocus = () => ref.current?.focus();
  const isExternal = isExternalUrl(href);

  return (
    <NextLink
      href={href}
      ref={ref}
      onClick={onClick ? (e) => onClick(e, refocus) : undefined}
      rel={isExternal ? 'noopener noreferrer nofollow' : undefined}
      target={target ? target : isExternal ? '_blank' : undefined}
      className={cx(
        styles.Button,
        size && styles[`size-${[size]}`],
        theme && styles[`theme-${[theme]}`],
        align && styles[`align-${[align]}`],
        shape && styles[`shape-${[shape]}`],
        iconPosition && styles[`icon-position-${iconPosition}`],
        iconOnly && styles[`icon-only-${[iconOnly]}`]
      )}
      {...attributes}
    >
      {icon && (
        <Icon
          icon={icon}
          theme={iconTheme != null ? iconTheme : theme !== 'primary' ? 1 : 0}
          size={iconSize}
        />
      )}

      <span className={styles.Label}>{children}</span>
    </NextLink>
  );
}
