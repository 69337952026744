const symbols: Record<string, string> = {
  $: 'dollar',
  '%': 'procent',
  '&': 'en',
  '¢': 'cent',
  '£': 'pond',
  '¤': 'valuta',
  '¥': 'yen',
  '©': '(c)',
  '®': '(r)',
  '฿': 'baht',
  '–': '-',
  '†': '+',
  '₣': 'frank',
  '₤': 'lira',
  '₧': 'peseta',
  '₨': 'roepie',
  '₹': 'roepie',
  '€': 'euro',
  '₭': 'kip',
  '₱': 'peso',
  '₺': 'lira',
  '₿': 'bitcoin',
  '℠': 'sm',
  '™': 'tm',
  '∆': 'delta',
  '∑': 'som',
  '∞': 'oneindig',
  '♥': 'liefde',
  元: 'yuan',
  円: 'yen',
};

function replaceSymbols(str: string) {
  return Array.from(str)
    .map((char) => symbols[char] || char)
    .join('');
}

export function encodePathSegment(str: string) {
  return replaceSymbols(str.trim())
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // remove accents.
    .replace(/[^a-zA-Z0-9-_+. ]/g, '.') // replace remaining special characters with a dot.
    .replace(/\s{1,128}/g, '.') // replace consecutive spaces with a dot.
    .replace(/\.{1,128}/g, '.'); // replace consecutive dots with a single dot
}

export function decodePathSegment(str: string) {
  return str.replace(/\.{1,128}/g, ' ');
}
