import loadImage from 'blueimp-load-image';

type Max = { maxWidth: number; maxHeight: number };

function resizeCanvas(
  originalCanvas: HTMLCanvasElement,
  { maxHeight, maxWidth }: Max
): HTMLCanvasElement {
  let { width, height } = originalCanvas;

  if (width < maxWidth && height < maxHeight) {
    return originalCanvas;
  }

  if (width > height) {
    if (width > maxWidth) {
      height = height * (maxWidth / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = width * (maxHeight / height);
      height = maxHeight;
    }
  }

  const resized = document.createElement('canvas');
  resized.width = width;
  resized.height = height;
  const context = resized.getContext('2d');

  if (!context) {
    return originalCanvas;
  }

  context.drawImage(
    originalCanvas,
    0,
    0,
    originalCanvas.width,
    originalCanvas.height,
    0,
    0,
    width,
    height
  );

  return resized;
}

export async function downsizeCanvas(
  canvas: HTMLCanvasElement,
  { maxHeight, maxWidth }: Max
): Promise<Blob> {
  return new Promise((resolve, reject) =>
    resizeCanvas(canvas, {
      maxHeight,
      maxWidth,
    }).toBlob((blob) => (blob ? resolve(blob) : reject()))
  );
}

export async function downsizeImage(
  file: File,
  { maxHeight, maxWidth }: Max
): Promise<Blob> {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return file;
  }

  const bitmap = await createImageBitmap(file, { resizeQuality: 'high' });
  canvas.width = bitmap.width;
  canvas.height = bitmap.height;
  context.drawImage(bitmap, 0, 0);

  return downsizeCanvas(canvas, { maxHeight, maxWidth });
}

// https://github.com/blueimp/JavaScript-Load-Image/blob/master/README.md#orientation
export function fixOrientation(
  file: File,
  { maxHeight, maxWidth }: Max
): Promise<Blob> {
  return new Promise((resolve, reject) =>
    loadImage(
      file,
      (canvas) =>
        (canvas as HTMLCanvasElement).toBlob((blob) =>
          blob ? resolve(blob) : reject()
        ),
      {
        maxHeight,
        maxWidth,
        canvas: true,
        orientation: true,
      }
    )
  );
}
