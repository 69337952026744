import { localUrls } from './env';

export function isExternalUrl(href: string): boolean {
  if (/^https?:/.test(href) === false) {
    return false;
  }

  const url = new URL(href);

  for (const localUrl of localUrls) {
    if (url.origin === localUrl.origin) {
      return false;
    }
  }

  return true;
}
