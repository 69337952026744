import { cx } from '@/util/cx';
import { ReactNode, forwardRef, ElementType } from 'react';
import { getGapModifiers, Gaps } from './util';
import { Responsive, getResponsive } from '@/util/getResponsive';

type TagName = keyof JSX.IntrinsicElements;

type Props = {
  children: ReactNode;
  gap?: Gaps;
  dir?: Responsive<'column' | 'row' | 'column-reverse' | 'row-reverse'>;
  align?: Responsive<'start' | 'center' | 'end'>;
  justify?: Responsive<
    'start' | 'space-between' | 'end' | 'center' | 'space-around'
  >;
  tag?: TagName;
  wrap?: boolean;
  grow?: boolean;
  onClick?: () => void;
  itemType?: string;
  tabIndex?: number;
  padding?: Responsive<1 | 2 | 3 | 4 | 5 | 6>;
};

export const Flex = forwardRef<HTMLElement, Props>(function Flex(
  {
    children,
    gap,
    dir,
    align,
    justify,
    padding,
    wrap,
    grow,
    tag = 'div',
    onClick,
    itemType,
    tabIndex,
  }: Props,
  ref
) {
  const gapModifiers: string[] = getGapModifiers(gap);
  const dirs = getResponsive('fd', dir);
  const aligns = getResponsive('ai', align);
  const justifies = getResponsive('jc', justify);
  const paddings = getResponsive('pad', padding);

  const Tag = tag as ElementType;

  return (
    <Tag
      ref={ref}
      itemType={itemType}
      itemScope={itemType ? true : false}
      onClick={onClick}
      tabIndex={tabIndex}
      role={onClick ? 'button' : undefined}
      className={cx(
        'Flex',
        gapModifiers,
        wrap && 'wrap',
        grow && 'grow',
        dirs,
        aligns,
        justifies,
        paddings
      )}
    >
      {children}
    </Tag>
  );
});
