import z from 'zod';

export const mailFrequencySchema = z.enum([
  'never',
  'direct',
  'daily',
  'weekly',
]);

export const mailFrequencyOptionSchema = z.object({
  label: z.string(),
  value: mailFrequencySchema,
});
