import styles from './Icon.module.scss';
import { cx } from '@/util/cx';
import { SyntheticEvent } from 'react';
import { iconSchema } from './schema';
import z from 'zod';

export type IconType = z.infer<typeof iconSchema>;

export type IconTheme = 0 | 1 | 2 | 3 | 4 | 5;

export type IconSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

type Props = {
  icon: IconType;
  theme?: IconTheme;
  size?: IconSize;
  title?: string;
  onClick?: (e: SyntheticEvent) => void;
};

export function Icon({ icon, theme, size, title, onClick }: Props) {
  const Tag = onClick ? 'button' : 'span';
  const type = onClick ? 'button' : undefined;

  return (
    <Tag
      title={title}
      onClick={onClick}
      type={type}
      className={cx(
        styles.Icon,
        styles[icon],
        theme != null && styles[`theme-${theme}`],
        size != null && styles[`size-${size}`]
      )}
    />
  );
}
