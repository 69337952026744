import { createLocalStorage } from '@/util/storage';
import z from 'zod';

export const authSchema = z.object({
  token_type: z.string(),
  expires_in: z.number(),
  access_token: z.string(),
  refresh_token: z.string(),
});

export type AuthType = z.infer<typeof authSchema>;

export const authStorage = createLocalStorage({
  auth: authSchema.nullable(),
});
