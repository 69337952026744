import env from '@/util/env';

const { GTAG_ID } = env;
const containerUrl = 'https://tikkertje.nhnieuws.nl';

let initialized = false;

export function loadGtag() {
  if (!GTAG_ID?.length || initialized) {
    return;
  }

  initialized = true;

  window.dataLayer = window.dataLayer || [];

  // eslint-disable-next-line
  function gtag(...args: any) {
    // eslint-disable-next-line
    window.dataLayer.push(arguments);
  }

  window.gtag = gtag;

  gtag('js', new Date());
  gtag('config', GTAG_ID, {
    server_container_url: containerUrl,
  });

  const script = document.createElement('script');
  script.async = true;
  script.src = `${containerUrl}/gtag/js?id=${GTAG_ID}`;
  document.head.appendChild(script);
}
