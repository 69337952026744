import { cx } from '@/util/cx';
import { ReactNode, useEffect, useRef } from 'react';
import styles from './Modal.module.scss';

export function Modal({
  open,
  children,
  onRequestClose,
  maxWidth,
  title,
}: {
  open: boolean;
  children: ReactNode;
  onRequestClose: () => void;
  maxWidth?: number;
  title?: string;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function close(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        onRequestClose();
      }
    }

    document.addEventListener('keyup', close);
    return () => {
      document.removeEventListener('keyup', close);
    };
  }, [onRequestClose]);

  useEffect(() => {
    if (open && ref.current) {
      ref.current.focus();
    }
  }, [open]);

  return (
    <div
      ref={ref}
      inert={open ? null : ''}
      role="dialog"
      aria-label={title}
      tabIndex={-1}
      className={cx(styles.ModalContainer, open && styles.open)}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onRequestClose();
        }
      }}
    >
      <div
        className={styles.Modal}
        style={maxWidth ? { maxWidth: `${maxWidth}rem` } : undefined}
      >
        <CloseButton onClick={onRequestClose} />

        <div className={styles.ModalBody}>{children}</div>
      </div>
    </div>
  );
}

function CloseButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type="button"
      title="Sluit dialoogvenster"
      className={styles.CloseButton}
      onClick={onClick}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" aria-hidden="true">
        <path
          d="M22.92,20.312,20.668,18.06,17.94,15.333l-2.358-2.358c-.323-.323-.639-.655-.968-.975l.741-.741,2.252-2.252,2.727-2.727L22.693,3.92c.383-.383.779-.754,1.151-1.151l.016-.016a.277.277,0,0,0,0-.385L21.854.362,21.572.081a.277.277,0,0,0-.385,0l-.937.937L18,3.27,15.27,6,12.912,8.356l-.782.782c-.062.062-.128.124-.193.187L11.2,8.583,8.944,6.331,6.216,3.6,3.858,1.245C3.475.862,3.1.466,2.707.094L2.691.078a.277.277,0,0,0-.385,0L.3,2.084l-.281.281a.277.277,0,0,0,0,.385l.937.937L3.208,5.94,5.935,8.667l2.358,2.358.782.782c.062.062.124.128.187.193l-.742.742L6.268,14.994,3.541,17.721,1.182,20.08C.8,20.462.4,20.834.032,21.23l-.016.016a.277.277,0,0,0,0,.385l2.006,2.006.281.281a.277.277,0,0,0,.385,0l.937-.937L5.878,20.73,8.6,18l2.358-2.358.782-.782c.062-.062.128-.124.193-.187l.742.742,2.252,2.252L17.659,20.4l2.358,2.358c.383.383.754.779,1.151,1.151l.016.016a.277.277,0,0,0,.385,0l2.006-2.006.281-.281a.277.277,0,0,0,0-.385l-.937-.937Z"
          transform="translate(0.062 0)"
          fill="currentColor"
        />
      </svg>
    </button>
  );
}

export function ModalOverlay({
  open,
  onRequestClose,
}: {
  open: boolean;
  onRequestClose: () => void;
}) {
  useEffect(() => {
    document.documentElement.classList.toggle('no-scroll', open);
  }, [open]);

  return (
    <div
      onClick={onRequestClose}
      className={cx(styles.ModalOverlay, open && styles.open)}
    />
  );
}
