import { SortKey } from '@/resource/ad';
import { get, localPost, post } from '@/util/api';
import { queryClient } from '@/util/queryClient';
import z from 'zod';
import { create } from 'zustand';
import { AdType, adItemSchema, adStatusSchema, sortKeySchema } from './ad';
import { authSchema, authStorage } from './auth';
import { MessageItemType } from './conversation';
import { dataArray, dataItem } from './helpers';
import { PrivateProfileItemType, privateProfileItemSchema } from './profile';
import { MailFrequencyType } from './settings';

export function login({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return localPost('/api/oauth2/login', authSchema, {
    username: email,
    password,
  }).then((auth) => {
    authStorage.set('auth', auth);
    return auth;
  });
}

export function loginGoogle({ code }: { code: string }) {
  return localPost('/api/oauth2/login/google', authSchema, {
    code,
  }).then((auth) => {
    authStorage.set('auth', auth);
    return auth;
  });
}

export function loginFacebook({ code }: { code: string }) {
  return localPost('/api/oauth2/login/facebook', authSchema, {
    code,
  }).then((auth) => {
    authStorage.set('auth', auth);
    return auth;
  });
}

const reportedAdSchema = z.object({
  id: z.number(),
  ad_id: z.number(),
  ad_report_reason_id: z.number(),
});

const favoritesSchema = z.object({
  offer: z.number().array(),
  request: z.number().array(),
});

const adSearchSchema = z.object({
  id: z.number(),
  type: z.enum(['request', 'offer']),
  category_ids: z.number().array(),
  distance: z.number().nullable(),
  keyword: z.string().nullable(),
  location_id: z.number().nullable(),
  place: z.string().nullable(),
  postal_code: z.number().nullable(),
  sort: sortKeySchema,
  deleted: z.number(),
});

const profileNotificationSchema = z.object({
  id: z.number(),
  profile_id: z.number(),
  message: z.string(),
  created: z.string().datetime({ offset: true }),
  seen_at: z.string().datetime({ offset: true }).nullable(),
  type: z.literal('profile_notifications'),
});

const statusNotificationSchema = z.object({
  id: z.number(),
  ad: dataItem(adItemSchema),
  status: adStatusSchema,
  created: z.string().datetime({ offset: true }),
  seen_at: z.string().datetime({ offset: true }).nullable(),
  profile_id: z.number(),
  reason: z.string().nullish(),
  type: z.literal('ad_status_notifications'),
});

const systemNotificationSchema = z.discriminatedUnion('type', [
  profileNotificationSchema,
  statusNotificationSchema,
]);

const unreadMessageSchema = z.object({
  ad_id: z.number(),
  ad_owner_id: z.number(),
  responder_id: z.number(),
});

const rawAccountSchema = z.object({
  data: privateProfileItemSchema,
  favorites: favoritesSchema,
  reported_ads: reportedAdSchema.array(),
  ad_searches: adSearchSchema.array(),
  reported_message_ids: z.number().array(),
  blocked_profile_ids: z.number().array(),
  unread_messages: unreadMessageSchema.array(),
  unseen_notification_count: z.number(),
});

type RawAccountType = z.infer<typeof rawAccountSchema>;
type FavoritesType = z.infer<typeof favoritesSchema>;
type ReportedAdType = z.infer<typeof reportedAdSchema>;
type UnreadMessageType = z.infer<typeof unreadMessageSchema>;
export type AdSearchType = z.infer<typeof adSearchSchema>;
export type StatusNotificationType = z.infer<typeof statusNotificationSchema>;
export type ProfileNotificationType = z.infer<typeof profileNotificationSchema>;
export type SystemNotificationType = z.infer<typeof systemNotificationSchema>;

export type AccountState = {
  profile: PrivateProfileItemType | null;
  favorites: FavoritesType;
  reportedAds: ReportedAdType[];
  adSearches: AdSearchType[];
  reportedMessageIds: number[];
  blockedProfileIds: number[];
  unreadMessages: UnreadMessageType[];
  unseenNotificationCount: number;
};

type AccountActions = {
  setProfile: (profile: PrivateProfileItemType) => void;
  setFavorites: (favorites: FavoritesType) => void;
  setReportedAds: (reportedAds: ReportedAdType[]) => void;
  setAdSearches: (adSearches: AdSearchType[]) => void;
  setUnreadMessages: (unreadMessages: UnreadMessageType[]) => void;
  setReportedMessageIds: (reportedMessageIds: number[]) => void;
  setBlockedProfileIds: (blockedProfileIds: number[]) => void;
  setUnseenNotificationCount: (unseenNotificationCount: number) => void;
  clear: () => void;
};

export type AccountStore = AccountState & AccountActions;

const initialAccountState: AccountState = {
  profile: null,
  favorites: {
    offer: [],
    request: [],
  },
  reportedAds: [],
  adSearches: [],
  unreadMessages: [],
  reportedMessageIds: [],
  blockedProfileIds: [],
  unseenNotificationCount: 0,
};

export const useAccount = create<AccountStore>((set) => ({
  setProfile: (profile) => {
    set((state) => ({ ...state, profile }));
  },

  setFavorites: (favorites) => {
    set((state) => ({ ...state, favorites }));
  },

  setReportedAds: (reportedAds) => {
    set((state) => ({ ...state, reportedAds }));
  },

  setAdSearches: (adSearches) => {
    set((state) => ({ ...state, adSearches }));
  },

  setUnreadMessages: (unreadMessages) => {
    set((state) => ({ ...state, unreadMessages }));
  },

  setReportedMessageIds: (reportedMessageIds) => {
    set((state) => ({ ...state, reportedMessageIds }));
  },

  setBlockedProfileIds: (blockedProfileIds) => {
    set((state) => ({ ...state, blockedProfileIds }));
  },

  setUnseenNotificationCount: (unseenNotificationCount) => {
    set((state) => ({ ...state, unseenNotificationCount }));
  },

  clear: () => {
    set((state) => ({ ...state, ...initialAccountState }));
  },

  ...initialAccountState,
}));

export function logout() {
  useAccount.getState().clear(); // reset state.
  authStorage.set('auth', null); // remove tokens.
  queryClient.clear(); // clear query cache.
}

/* Api helpers */
export function getAccount(): Promise<null | RawAccountType> {
  const auth = authStorage.get('auth', null);
  if (!auth) {
    return Promise.resolve(null);
  }

  return get('/account', rawAccountSchema).then(
    ({
      data,
      favorites,
      reported_ads,
      ad_searches,
      unread_messages,
      reported_message_ids,
      blocked_profile_ids,
      unseen_notification_count,
    }) => {
      const state = useAccount.getState();
      state.setProfile(data);
      state.setFavorites(favorites);
      state.setReportedAds(reported_ads);
      state.setAdSearches(ad_searches);
      state.setUnreadMessages(unread_messages);
      state.setReportedMessageIds(reported_message_ids);
      state.setBlockedProfileIds(blocked_profile_ids);
      state.setUnseenNotificationCount(unseen_notification_count);

      return {
        data,
        favorites,
        reported_ads,
        ad_searches,
        unread_messages,
        reported_message_ids,
        blocked_profile_ids,
        unseen_notification_count,
      };
    }
  );
}

export function getNotificationCount({
  profile,
  unreadMessages,
  unseenNotificationCount,
}: AccountState): number {
  if (!profile) {
    return 0;
  }

  if (profile.deactivated) {
    return 0;
  }

  return unreadMessages.length + unseenNotificationCount;
}

export function register({
  firstName,
  lastName,
  postalCode,
  email,
  password,
  phone,
  locationId,
  allowContact,
}: {
  firstName: string;
  lastName: string;
  postalCode: string;
  email: string;
  password: string;
  phone: string;
  locationId: number;
  allowContact: boolean;
}) {
  return post(
    '/account/register',
    z.string().array(),
    z.object({
      reason: z.enum([
        'unknown',
        'profile_exists',
        'location_not_found',
        'could_not_create_profile',
      ]),
    }),
    {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      phone,
      postal_code: postalCode,
      location_id: locationId,
      allow_contact: allowContact ? 1 : 0,
    }
  );
}

export function deleteAccount() {
  return post('/account/delete', z.any(), z.any(), {});
}

export function activateAccount() {
  return post('/account/activate', z.any(), z.any(), {});
}

export function deactivateAccount() {
  return post('/account/deactivate', z.any(), z.any(), {});
}

export function forgotPassword({ email }: { email: string }) {
  return post('/account/forgot-password', z.any(), z.any(), { email });
}

export function changePassword({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}) {
  return post(
    '/account/change-password',
    z.any(),
    z.object({
      reason: z.enum(['weak_password', 'invalid_current_password']),
    }),
    { current_password: currentPassword, new_password: newPassword }
  );
}

export function resetPassword({
  hash,
  password,
}: {
  hash: string;
  password: string;
}) {
  return post(
    '/account/reset-password',
    z.any(),
    z.object({
      reason: z.enum([
        'already_logged_in',
        'weak_password',
        'reset_not_found',
        'reset_invalid',
      ]),
    }),
    { hash, password }
  );
}

export function verifyCode({ code, email }: { email: string; code: string }) {
  return post(
    '/account/verify',
    z.object({ id: z.number() }),
    z.object({
      reason: z.enum([
        'expired',
        'too_many_attempts',
        'invalid_code',
        'unauthorized',
      ]),
    }),
    { code, email }
  );
}

export type AdSearchInput = {
  ad_search_categories: number[];
  keyword?: string;
  distance?: number;
  location_id?: number;
  sort: SortKey;
  type: AdType;
};

export function saveSearch({
  ad_search_categories,
  keyword = '',
  distance,
  location_id,
  sort,
  type,
}: AdSearchInput) {
  return post(`/market/save-search`, z.object({ id: z.number() }), z.any(), {
    ad_search_categories,
    keyword,
    distance,
    location_id,
    sort,
    type,
  });
}

export function deleteSearch({ adSearchId }: { adSearchId: number }) {
  return post(`/market/delete-search`, z.string().array(), z.string().array(), {
    ad_search_id: adSearchId,
  });
}

export function disableSearch({ adSearchId }: { adSearchId: number }) {
  return post(
    `/market/disable-search`,
    z.string().array(),
    z.string().array(),
    {
      ad_search_id: adSearchId,
    }
  );
}

export function enableSearch({ adSearchId }: { adSearchId: number }) {
  return post(`/market/enable-search`, z.string().array(), z.string().array(), {
    ad_search_id: adSearchId,
  });
}

export async function getSystemNotifications() {
  const { data } = await get(
    '/notifications',
    dataArray(systemNotificationSchema)
  );
  return data;
}

/* Status notifications */
export function isStatusNotification(
  notification: SystemNotificationType
): notification is StatusNotificationType {
  return notification.type === 'ad_status_notifications';
}

export async function deleteStatusNotifications(
  notifications: StatusNotificationType[]
) {
  return post(
    '/status-notifications/delete',
    z.object({ deleted: z.number() }),
    z.object({
      reason: z.enum(['unauthorized']),
    }),
    { ids: notifications.map(({ id }) => id) }
  );
}

export async function markStatusNotificationsAsSeen(
  notifications: StatusNotificationType[]
) {
  return post(
    '/status-notifications/mark-as-seen',
    z.object({ updated: z.number() }),
    z.object({
      reason: z.enum(['unauthorized']),
    }),
    { ids: notifications.map(({ id }) => id) }
  );
}

/* Profile notifications */
export function isProfileNotification(
  notification: SystemNotificationType
): notification is ProfileNotificationType {
  return notification.type === 'profile_notifications';
}

export async function deleteProfileNotifications(
  notifications: ProfileNotificationType[]
) {
  return post(
    '/profile-notifications/delete',
    z.object({ deleted: z.number() }),
    z.object({
      reason: z.enum(['unauthorized']),
    }),
    { ids: notifications.map(({ id }) => id) }
  );
}

export async function markProfileNotificationsAsSeen(
  notifications: ProfileNotificationType[]
) {
  return post(
    '/profile-notifications/mark-as-seen',
    z.object({ updated: z.number() }),
    z.object({
      reason: z.enum(['unauthorized']),
    }),
    { ids: notifications.map(({ id }) => id) }
  );
}

/* All notifications */
export async function deleteNotifications(
  notifications: SystemNotificationType[]
) {
  return Promise.all([
    deleteProfileNotifications(notifications.filter(isProfileNotification)),
    deleteStatusNotifications(notifications.filter(isStatusNotification)),
  ]);
}

export async function markNotificationsAsSeen(
  notifications: SystemNotificationType[]
) {
  return Promise.all([
    markProfileNotificationsAsSeen(notifications.filter(isProfileNotification)),
    markStatusNotificationsAsSeen(notifications.filter(isStatusNotification)),
  ]);
}

/* Update profile */
export function updateProfile({
  firstName,
  lastName,
  allowContact,
  locationId,
  phone,
}: {
  firstName: string;
  lastName: string;
  allowContact: boolean;
  locationId: number;
  phone: string;
}) {
  return post(
    `/account/update`,
    z.object({ id: z.number() }),
    z.object({
      reason: z.enum(['profile_exists', 'unexpected_password', 'unauthorized']),
    }),
    {
      first_name: firstName,
      last_name: lastName,
      allow_contact: allowContact ? 1 : 0,
      location_id: locationId,
      phone,
    }
  );
}

export function updateChatMailFrequency({
  frequency,
}: {
  frequency: MailFrequencyType;
}) {
  return post(
    `/account/update`,
    z.object({ id: z.number() }),
    z.object({
      reason: z.enum(['profile_exists', 'unexpected_password', 'unauthorized']),
    }),
    {
      chat_mail_frequency: frequency,
    }
  );
}

export function updateAdMailFrequency({
  frequency,
}: {
  frequency: MailFrequencyType;
}) {
  return post(
    `/account/update`,
    z.object({ id: z.number() }),
    z.object({
      reason: z.enum(['profile_exists', 'unexpected_password', 'unauthorized']),
    }),
    {
      ad_mail_frequency: frequency,
    }
  );
}

export function isReportedMessage(
  { reportedMessageIds, profile }: AccountState,
  { id }: MessageItemType
) {
  if (!profile) {
    return false;
  }

  return reportedMessageIds.includes(id);
}
